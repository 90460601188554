import api from "./api";
import Swal from "sweetalert2";


//Para agregar un nuevo proveedor 
const createSupplier = async (name, identification, address, phone, webPage, email, idUsuario) => {
    const nuevoProveedor = {
        name: name,
        identification: identification,
        direction: address,
        phone: phone,
        webPage: webPage,
        email: email,
        creator: idUsuario,
        contacts: []
    }
    const response = await api.post('/suppliers/create', nuevoProveedor).then((res) => {
        if (res.data.status) {
            //Alerta de agregado
            Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 2000
            })
        }
    }).catch(async (err) => {
        Swal.fire({
            icon: 'error',
            title: err.response.data.message,
            showConfirmButton: true
        })
    })
    return response;
}

//Funcion para eliminar un proveedor 
const deleteSupplier = async (id) => {
    Swal.fire({
        title: '¿Estás seguro de eliminar el proveedor?',
        text: "¡No podrás revertirlo!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
    }).then(async (result) => {
        if (result.isConfirmed) {
            await api.delete('/suppliers/delete/' + id).then((res) => {
                if (res.data.status) {
                    //Alerta de editado
                    Swal.fire({
                        icon: 'success',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            })
        }
    }).catch((err) => {
        Swal.fire({
            icon: 'error',
            title: err.response.data?.message,
            showConfirmButton: true
        })
    })
}


//Funcion para actualizar el proveedor
const updateSupplier = async (idSupplier, name, identification, address, phone, webPage, email, idUsuario) => {
    const nuevoProveedor = {
        name: name,
        identification: identification,
        direction: address,
        phone: phone,
        webPage: webPage,
        email: email,
        creator: idUsuario,
        contacts: []
    }
    const response = await api.put('/suppliers/update/' + idSupplier, nuevoProveedor).then((res) => {
        if (res.data.status) {
            //Alerta de actualizado
            Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 2000
            })
        }
    }).catch((err) => {
        Swal.fire({
            icon: 'error',
            title: err.response.data?.message,
            showConfirmButton: true
        })
    })
    return response;
}

export default { createSupplier, deleteSupplier, updateSupplier }