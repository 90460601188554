import api from "./api";
import Swal from "sweetalert2";

const createUsuario = async (image, name, email, password, perfil, role) => {
    let salida = {};
    let formData = new FormData()
    formData.append('image', image.data)
    formData.append('name', name)
    formData.append('email', email)
    formData.append('password', password)
    formData.append('perfil', perfil)
    formData.append('role', role)
    const res = await api.post('/user/crear', formData).then(async res => {
        salida = res.data;
        const msg = res.data.status
        if (msg !== 'Bienvenido') {
            //logs de error al backend
            const mensaje = {
                message: "Error al añadir un usuario",
                level: 3
            }
            await api.post('/logger', mensaje)
            ///Mensaje de error
            Swal.fire({
                icon: 'error',
                title: msg,
                showConfirmButton: false,
                timer: 1500
            })
        } else {
            Swal.fire({
                icon: 'success',
                title: "Registrado!!",
                showConfirmButton: false,
                timer: 1500
            })
        }
    }).catch(error =>
        Swal.fire({
            icon: 'error',
            title: error.response.data.message || "Error desconocido",
            showConfirmButton: false
        })
    )
    return salida;
}


const updateUser = async (id, image, name, email, perfil, role) => {
    let salida = {};
    const form = new FormData();
    form.append('image', image)
    form.append('name', name)
    form.append('email', email)
    form.append('perfil', perfil)
    form.append('role', role)
    await api.put('/user/update/' + id, form).then(async res => {
        salida = res.data
        console.log(salida)
        const msg = res.data.status
        //Alerta de editado
        if (msg !== 'Usuario actualizado') {
            //logs de error al backend
            const mensaje = {
                message: "Error al actualizar un usuario",
                level: 3
            }
            await api.post('/logger', mensaje)
            ///Mensaje de error
            Swal.fire({
                icon: 'error',
                title: msg,
                showConfirmButton: false,
                timer: 1500
            })
        } else {
            Swal.fire({
                icon: 'success',
                title: msg,
                showConfirmButton: false,
                timer: 1500
            })
        }
    }).catch(error => {
        console.log(error)
        Swal.fire({
            icon: 'error',
            title: error.response.data?.message || "Error desconocido",
            showConfirmButton: false
        })
    })

    return salida;
}

const changePassword = async(id, password) => {
    const usuario = JSON.parse(localStorage.getItem('user'))
    const newPassword = {
        newPassword: password,
        idUserAdministrator: usuario.id
    }
    await api.put('/user/updatePassword/' + id, newPassword).then((res) => {
        console.log(res.data)
        if(res.data.status){
            Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
            });
        }else {
            Swal.fire({
                icon: 'error',
                title: res.data.message,
                showConfirmButton: true
            })
        }
        
    }).catch((err) => {
        console.log(err)
        Swal.fire({
            icon: 'error',
            title: err.response.data.message,
            showConfirmButton: true
        })
    })
}

export default { createUsuario, updateUser, changePassword }