// ** MUI Imports
import * as React from 'react';
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
//Paginacion
import TablePagination from '@mui/material/TablePagination';
// ** Icons Imports

import api from "../../services/api"
import abcclasification from '../../services/abcclasification';


const SalesByCountries = () => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [productsList, setproductsList] = React.useState([]);
    const fetchData = async () => {
        await api.get('/products/').then(res => {
            setproductsList(res.data.sort((a, b) => {
                if (a.clasification.type.toLowerCase() < b.clasification.type.toLowerCase()) {
                    return -1;
                }
                if (a.clasification.type.toLowerCase() > b.clasification.type.toLowerCase()) {
                    return 1;
                }
                return 0;
            }));
        }).catch(error => console.log(error))
    }
    React.useEffect(() => {
        fetchData();
    }, []);

    //Paginacion
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    //Paginacion
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
   

    return (
        <Card>
            <CardHeader
                title='Productos'
                titleTypographyProps={{ sx: { lineHeight: '1.2 !important', letterSpacing: '0.31px !important' } }}
            />
            <CardContent sx={{ pt: theme => `${theme.spacing(2)} !important` }}>
                {productsList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(item => (
                        <Box
                            key={item._id}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mb: 2.875
                            }}
                        >
                            <Avatar
                                sx={{
                                    width: 38,
                                    height: 38,
                                    marginRight: 3,
                                    fontSize: '1rem',
                                    color: 'common.white',
                                    backgroundColor: item.clasification.type === "A" ? `success.main` : item.clasification.type === "B" ? `warning.main` : `error.main`
                                }}
                            >
                                {item.clasification.type}
                            </Avatar>

                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Box sx={{ marginRight: 2, display: 'flex', flexDirection: 'column' }}>
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography sx={{ mr: 0.5, fontWeight: 600, letterSpacing: '0.25px' }}>{item.title}</Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            {item.name}
                                        </Box>
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', textAlign: 'end', flexDirection: 'column' }}>
                                    <Typography sx={{ fontWeight: 600, fontSize: '0.875rem', lineHeight: 1.72, letterSpacing: '0.22px' }}>
                                        {item.clasification.type}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    ))}
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={productsList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </CardContent>
        </Card>
    )
}

export default SalesByCountries