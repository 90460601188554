import React from 'react'
import { Box, Link , Button, Stack , Typography, Breadcrumbs  } from '@mui/material';

import { useNavigate } from "react-router-dom";

//assets
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
//components
import Header from "../../../components/header/Header";
import PurchaseOrderTable from '../../../components/table/PurchaseOrderTable';

const PurchaseOrders = () => {
    const navigate = useNavigate()

    const breadcrumbs = [
        <Link underline="none" key="1" color="inherit" disable >
            Inventario
        </Link>,
        <Link underline="none" key="1" color="inherit"  >
        Ordenes de compra
    </Link>,
        <Typography key="3" color="text.primary">
            Nueva Orden
        </Typography>,
    ];

    return (
        <React.Fragment>
            {/* Breadcrumbx */}
            <Box m="20px">
                <Stack spacing={2}>
                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                    >
                        {breadcrumbs}
                    </Breadcrumbs>
                </Stack>
                {/* HEADER */}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title="ORDENES DE COMPRA" subtitle="Administra tus Ordenes de Compra" />
                    <Box>
                        <Button variant="contained" onClick={() => navigate("/scm/purchaseOrders/add-invoice")}>
                            <AddIcon />
                            Nuevo Orden de Compra
                        </Button>
                    </Box>
                </Box>
                <PurchaseOrderTable/>
            </Box>
        </React.Fragment>
    )
}
export default PurchaseOrders;