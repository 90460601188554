import api from "./api";
import Swal from "sweetalert2";

const getProductos = async() => {
    let salida = {}
    const body = {
        database: "warehouse",
        
    };

    await api.post('/global/getAll', body).then(res => {
        salida = res.data
    }).catch(err => console.log(err))

    return salida;
}

const createStore = async (name, ubication) => {
    let salida = {}
    const usuario = JSON.parse(localStorage.getItem('user'))
    console.log(usuario)
    const nuevoStore = {
        database: "warehouse",
        name: name,
        ubication: ubication,
        creator: usuario.id
    };

    await api.post('/global/', nuevoStore).then(res => {
        salida = res.data
    }).catch(err => salida = err.response?.data)

    return salida;
}
const deleteWarehouse = async (id) => {
    let salida = {}
    const body = {
        database: "warehouse",
        id: id
    };

    await api.post('/global/delete', body).then(res => {
        salida = res.data
    }).catch(err => console.log(err))

    return salida;
}
const updateStore = async (id, name, ubication) => {
    let salida = {}
    const body = {
        database: "warehouse",
        id: id,
        name: name,
        ubication: ubication
    };

    await api.put('/global/', body).then(res => {
        salida = res.data
    }).catch(err => console.log(err))

    return salida;
}

export default { getProductos, createStore, deleteWarehouse, updateStore }