import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ThemeProvider } from '@emotion/react';
import theme from "./themeConfig";
import { ProSidebarProvider } from 'react-pro-sidebar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
//ruta protegida
import ProtectedRoute from './util/ProtectedRoute';
//Pages
import Login from './components/auth/login/Login';
import Auth from './components/auth/Auth';
import ForgetPassword from './components/auth/ForgetPassword';
import Dashboard from './pages/Dashboard';
import Products from './pages/inventory/Products';
import Collections from './pages/inventory/Collections';
import Supplier from './pages/inventory/Supplier';
import PurchaseOrders from './pages/inventory/invoice/PurchaseOrders';
import ABCClassification from './pages/studyProducts/ABCClassification';
import ModelsPage from './pages/studyProducts/models/Models';

import App from './App';
import Profile from './pages/accountSettings/Profile';
import AdminUser from './pages/accountSettings/AdminUser';
import EditUser from './pages/accountSettings/EditUser';
import AddInvoice from './pages/inventory/invoice/AddInvoice';
import AdminRole from './pages/accountSettings/AdminRole';
import CreateModel from './sections/model/createModel/CreateModel';
import Inventory from './pages/inventory/Inventory';
import AddPurchaseOrder from './pages/inventory/AddPurchaseOrder';
import StockRoom from './pages/inventory/invoice/StockRoom';
import Stockroom from './pages/inventory/Stockroom';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ProSidebarProvider>
        <BrowserRouter basename={'/'}>
          <Routes>
            <Route path='/auth' element={<Auth />}>
              <Route path='login' element={<Login />} />
              <Route path='forgetPassword' element={<ForgetPassword />} />
            </Route>
            <Route path="/" element={<App />}>
              {/*<Route path='' element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              } />*/}
              <Route path='scm/products' element={
                <ProtectedRoute>
                  <Products />
                </ProtectedRoute>
              } />
              <Route path='scm/collections' element={
                <ProtectedRoute>
                  <Collections />
                </ProtectedRoute>
              } />
              <Route path='scm/suppliers' element={
                <ProtectedRoute>
                  <Supplier />
                </ProtectedRoute>
              } />
              <Route path='scm/purchaseOrders' element={
                <ProtectedRoute>
                  <PurchaseOrders />
                </ProtectedRoute>
              } />
              <Route path='scm/stockroom' element={
                <ProtectedRoute>
                  <Stockroom />
                </ProtectedRoute>
              } />
              <Route path='scm/purchaseOrders/add-invoice' element={
                <ProtectedRoute>
                  <AddPurchaseOrder />
                </ProtectedRoute>
              } />
              <Route path='scm/purchaseOrders/stockroom/:id' element={
                <ProtectedRoute>
                  <StockRoom />
                </ProtectedRoute>
              } />
              <Route path='scm/inventory/' element={
                <ProtectedRoute>
                  <Inventory />
                </ProtectedRoute>
              } />
              <Route path='studyProducts/ABCClassification' element={
                <ProtectedRoute>
                  <ABCClassification />
                </ProtectedRoute>
              } />
              <Route path='studyProducts/models' element={
                <ProtectedRoute>
                  <ModelsPage />
                </ProtectedRoute>
              } />
              <Route path='studyProducts/create-model' element={
                <ProtectedRoute>
                  <CreateModel />
                </ProtectedRoute>
              } />
              <Route path='profile' element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              } />
              <Route path='profile/adminUsers' element={
                <ProtectedRoute>
                  <AdminUser />
                </ProtectedRoute>
              } />
              <Route path='profile/admin-role' element={
                <ProtectedRoute>
                  <AdminRole />
                </ProtectedRoute>
              } />
              <Route path='profile/adminUsers/editUser/:id' element={
                <ProtectedRoute>
                  <EditUser />
                </ProtectedRoute>
              } />
            </Route>
          </Routes>
        </BrowserRouter>
      </ProSidebarProvider>
    </ThemeProvider>
  </React.StrictMode>
);