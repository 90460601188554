import { useCallback, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    TextField,
    Unstable_Grid2 as Grid
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';


export const AccountProfileDetails = () => {
    const data = JSON.parse(localStorage.getItem('user'))
    const [name, setName] = useState(data.name)
    const [email, setEmail] = useState(data.email)
    const [phone, setPhone] = useState("Sin Telefono")


    const handleSubmit = useCallback(
        (event) => {
            event.preventDefault();
        },
        []
    );

    const actualizar = () => {

    }
    return (
        <form
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit}
        >
            <Card>
                <CardHeader
                    subheader="La información puede ser editada"
                    title="Pefil"
                />
                <CardContent sx={{ pt: 0 }}>
                    <Box sx={{ m: -1.5 }}>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    helperText="Por favor, especifique el nombre de usuario"
                                    label="Nombre de Usuarios"
                                    name="usuername"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                    disable
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <TextField
                                    fullWidth
                                    label="Email"
                                    name="email"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    disable />
                            </Grid>
                            {/* 
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <TextField
                                    fullWidth
                                    label="Número de Telefonico"
                                    name="phone"
                                    type="number"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}   
                                />
    </Grid>*/}
                        </Grid>
                    </Box>
                </CardContent>
                <Divider />
                {/* <CardActions sx={{ justifyContent: 'flex-end' }}>
                    <Button variant="contained" startIcon={<SaveIcon />} onClick={actualizar}>
                        Guardar
                    </Button>
                </CardActions>
                */}

            </Card>
        </form>
    );
};