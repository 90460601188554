import React from "react";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

const labels = ["Punto de ReOrden", "Stock de Seguridad", "Stock Actual"];

const data = {
    labels: labels,
    datasets: [
        {
            label: "My First dataset",
            backgroundColor: "rgb(255, 99, 132)",
            borderColor: "rgb(255, 99, 132)",
            data: [0, 10, 5],
        },
    ],
};
const ChartModel = () => {

    return (
        <div>
            <Line data={data} />
        </div>
    );
}

export default ChartModel