import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import { useNavigate, useParams } from "react-router-dom";
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from "@mui/material/Stack"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import Link from "@mui/material/Link"
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ProfileImage from "../../assets/images/Sample_User_Icon.png"

// ** MUI Imports
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import TabContext from '@mui/lab/TabContext'
import { styled } from '@mui/material/styles'
import MuiTab from '@mui/material/Tab'

// ** Icons Imports
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import TabAccount from '../../components/tab/TabAccount';
import TabSecurity from '../../components/tab/TabSecurity.jsx';
import api from "../../services/api"

const Tab = styled(MuiTab)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        minWidth: 100
    },
    [theme.breakpoints.down('sm')]: {
        minWidth: 67
    }
}))

const TabName = styled('span')(({ theme }) => ({
    lineHeight: 1.71,
    fontSize: '0.875rem',
    marginLeft: theme.spacing(2.4),
    [theme.breakpoints.down('md')]: {
        display: 'none'
    }
}))

const EditUser = () => {
    const navigate = useNavigate()
    const {id} = useParams();
    // ** State
    const [value, setValue] = React.useState("")
    const [user, setUser] = React.useState({})

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    const fetchData = async () => {
        await api.get('/user/' + id).then(res => {
            setUser(res.data.usuario)
            setValue("user")
        }).catch(error => console.log(error))
    }

    React.useEffect(() => {
        fetchData();
    }, [id]);

    const breadcrumbs = [
        <Link underline="none" key="1" color="inherit" disable >
            Perfil
        </Link>,
        <Link underline="none" key="1" color="inherit" disable >
            Administrar Usuario
        </Link>,
        <Typography key="3" color="text.primary">
            Editar Usuario
        </Typography>,
    ];

    return (
        <React.Fragment>
            <Box m="20px">
                {/* Breadcrumbx */}
                <Stack spacing={2}>
                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                    >
                        {breadcrumbs}
                    </Breadcrumbs>
                </Stack>
            </Box>
            <Box m="20px">
                <Card >
                    <TabContext value={value}>
                        <TabList
                            onChange={handleChange}
                            aria-label='account-settings tabs'
                            sx={{ borderBottom: theme => `1px solid ${theme.palette.divider}` }}
                        >
                            <Tab
                                value='user'
                                label={
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <PermIdentityOutlinedIcon />
                                        <TabName>Usuario</TabName>
                                    </Box>
                                }
                            />
                            <Tab
                                value='security'
                                label={
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <LockOpenOutlinedIcon />
                                        <TabName>Seguridad</TabName>
                                    </Box>
                                }
                            />
                        </TabList>

                        <TabPanel sx={{ p: 0 }} value='user'>
                            <TabAccount user={user} />
                        </TabPanel>
                        <TabPanel sx={{ p: 0 }} value='security'>
                            <TabSecurity user={user} />
                        </TabPanel>
                    </TabContext>
                </Card>
            </Box>
        </React.Fragment>

    );
}

export default EditUser