import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Container, InputAdornment, TableSortLabel, TextField } from "@mui/material";
import { visuallyHidden } from '@mui/utils';
//Paginacion
import TablePagination from '@mui/material/TablePagination';
//assets
import DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from "@mui/icons-material/Search";

//services
import api from "../../services/api";
import products from '../../services/products';

import ModalEditProduct from '../modal/ModalEditProduct';

import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';


const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

function Row(props) {
    const navigate = useNavigate();
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openOptions = Boolean(anchorEl);

    const deleteProduct = async (id) => {
        Swal.fire({
            title: '¿Estás seguro de eliminar el producto?',
            text: "¡No podrás revertirlo!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) { }
            products.deleteProduct(id).then(res => {
                navigate(0);
                handleCloseOptions()
            })
        })
    }

    const handleClickOptions = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseOptions = () => {
        setAnchorEl(null);
    };
    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                {/*  <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
    </TableCell>*/}
                <TableCell >
                    {row.image === undefined
                        ? <img src="https://reclycar.es/assets/img/default.png" alt="imagen de perfil" height='50' /> :
                        <img src={row.image} alt="imagen de perfil" height='50' />}
                </TableCell>
                <TableCell component="th" scope="row" >{row.name}</TableCell>
                <TableCell align="right">{row.partNumber}</TableCell>
                <TableCell align="right">{row.sku}</TableCell>
                <TableCell align="right">{row.cost}</TableCell>
                <TableCell align="right">{row.price}</TableCell>
                <TableCell align="right">{row.stock}</TableCell>
                <TableCell align="right">{row.collectionName}</TableCell>
                <TableCell align="right">{row.observations}</TableCell>
                <TableCell align="right">
                    <Button
                        id="demo-customized-button"
                        aria-controls={openOptions ? 'demo-customized-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openOptions ? 'true' : undefined}
                        variant="contained"
                        disableElevation
                        onClick={handleClickOptions}
                        endIcon={<KeyboardArrowDownIcon />}
                    >
                        <MenuIcon />
                    </Button>
                    <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={openOptions}
                        onClose={handleCloseOptions}
                    >
                        <ModalEditProduct product={row} />
                        <MenuItem onClick={() => deleteProduct(row._id)} disableRipple>
                            <DeleteIcon />
                            Eliminar
                        </MenuItem>
                    </StyledMenu>
                </TableCell>
            </TableRow>
            {/* <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Detalles
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Utilidad ($)</TableCell>
                                        <TableCell >Clasificación</TableCell>
                                        <TableCell align="right">Modelo</TableCell>
                                        <TableCell align="right">Demanda Anual Promedio</TableCell>
                                        <TableCell align="right">Cantidad Óptima para realizar un pedido </TableCell>
                                        <TableCell align="right">Punto de Reorden para comparar más producto </TableCell>
                                        <TableCell align="right">Stock de seguridad para pedidos </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow >
                                        <TableCell component="th" scope="row">
                                            {row.utility}
                                        </TableCell>
                                        <TableCell align="right">{row.clasification.type}</TableCell>
                                        <TableCell align="right">{row.model?.name}</TableCell>
                                        <TableCell align="right">{row.averageAnnualDemand}</TableCell>
                                        <TableCell align="right">{row.model?.optimalAmount}</TableCell>
                                        <TableCell align="right">{row.model?.reorderPoint}</TableCell>
                                        <TableCell align="right">{row.model?.securityStock}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>*/}
        </React.Fragment >
    );
}
const headCells = [
    {
        id: 'image',
        numeric: false,
        disablePadding: true,
        label: '',
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Nombre',
    },
    {
        id: 'partNumber',
        numeric: false,
        disablePadding: false,
        label: 'Numero de Parte',
    },
    {
        id: 'sku',
        numeric: false,
        disablePadding: false,
        label: 'SKU',
    },
    {
        id: 'cost',
        numeric: true,
        disablePadding: false,
        label: 'Costo ($)',
    },
    {
        id: 'price',
        numeric: true,
        disablePadding: false,
        label: 'Precio ($)',
    },
    {
        id: 'stock',
        numeric: true,
        disablePadding: false,
        label: 'Stock',
    },
    {
        id: 'collectionName',
        numeric: true,
        disablePadding: false,
        label: 'Colección',
    },
    {
        id: 'observations',
        numeric: true,
        disablePadding: false,
        label: 'Observación',
    },
    {
        id: 'option',
        numeric: true,
        disablePadding: false,
        label: '',
    }
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } =
        props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


const ProductosTable = (props) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [productsList, setproductsList] = React.useState([]);
    const [productsListSearch, setproductsListSearch] = React.useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        const res = stableSort(productsList, getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
        )
        setproductsList(res)
    };

    //busqueda
    const handleChangeSearch = (event) => {
        setSearchTerm(event.target.value);
        if (!searchTerm) {
            setproductsList(productsListSearch)
        } else {
            filtrar(event.target.value);
        }
    };
    //el filtro de la busqueda por el termino de busqueda
    const filtrar = (terminoBusqueda) => {
        if (terminoBusqueda.length >= 3) {
            var resultadosBusqueda = productsListSearch.filter((item) => {
                if (item.name?.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
                    || item.sku?.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
                ) {
                    return item;
                }
            });
            setproductsList(resultadosBusqueda);
        }
    }
    const fetchData = async () => {
        await api.get('/products/').then(res => {
            const productos = res.data.sort((a, b) => {
                if (a.name?.toLowerCase() < b.name?.toLowerCase()) {
                    return -1;
                }
                if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
                    return 1;
                }
                return 0;
            })
            setproductsList(productos)
            setproductsListSearch(productos)
        }).catch(error => console.log(error))
    }

    React.useEffect(() => {
        fetchData();
    }, []);




    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
    // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
    // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
    // with exampleArray.slice().sort(exampleComparator)
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }


    //Paginacion
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    //Paginacion
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        <Paper sx={{ width: '100%', mb: 2, m: 1}}>
            <TableContainer >
                <Container maxWidth="md" sx={{ mt: 1 }}>
                    <TextField
                        id="search"
                        type="search"
                        label="Buscar Producto por el nombre o SKU"
                        value={searchTerm}
                        onChange={handleChangeSearch}
                        sx={{ width: 600 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Container>
                <Table aria-label="collapsible table" size='small'>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {productsList
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => (
                                <Row key={row._id} row={row} />
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={productsList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}

export default ProductosTable
