import React, { useEffect, useRef, useState } from 'react'
import { Box, Link, Button, Stack, Typography, Breadcrumbs, Grid, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Badge, TextField, IconButton, FormGroup, Divider, FormControl, InputLabel, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

import { useNavigate, useParams } from "react-router-dom";

//assets
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DeleteIcon from '@mui/icons-material/Delete';
//components
import Header from "../../../components/header/Header";
import PurchaseOrderTable from '../../../components/table/PurchaseOrderTable';
import api from "../../../services/api"
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import purchaserOrderService from '../../../services/purchaserOrderService';
import products from '../../../services/products';

import { v4 as uuidv4 } from 'uuid';


const defaultState = {
  _id: "",
  name: "",
  state: "Bien",
  serialNumber: "",
  observations: "",
  wareHouse: "",
  sku: "",
  amount: 1,
};
const defaultStateError = {
  _id: "",
  name: "",
  state: "Bien",
  serialNumber: "",
  observations: "",
  wareHouse: "",
  sku: "",
  amount: 1,
};
const optionState = ["Bien", "Con defectos"];

function RowForm({
  product,
  onChange,
  onRemove,
  state,
  serialNumber,
  observations,
  sku,
  wareHouse,
  amount
}) {
  let [count, setCount] = useState(amount);
  let [listBodega, setListBodega] = useState([]);

  const fetchData = async () => {
    const body = {
      database: "warehouse",

    };

    await api.post('/global/getAll', body).then(res => {
      setListBodega(res.data.stores)
    }).catch(error => console.log(error))
  }

  fetchData();

  function incrementCount() {
    count = count + 1;
    setCount(count);
    onChange("amount", count)
  }
  function decrementCount() {
    count = count - 1;
    setCount(count);
    onChange("amount", count)
  }
  return (
    <Grid container spacing={1} mb={2}>
      <Grid item xs={12} sm={5}>
        <TextField id="outlined-basic" label="Número de serie" variant="outlined" defaultValue={serialNumber} fullWidth={true}
          onChange={(e) => onChange("serialNumber", e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField fullWidth={true} id="outlined-basic" label="SKU" variant="outlined" defaultValue={sku} onChange={(e) => onChange("sku", e.target.value)}
          required
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={0}
        >
          <IconButton aria-label="edit" disabled={count === 1 && "disabled"} defaultValue={observations} onClick={decrementCount}>
            -
          </IconButton>
          <TextField
            id="outlined-number"
            type="number"
            defaultValue={1}
            fullWidth={true}
            onChange={(e) => onChange("amount", e.target.value)}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} />
          <IconButton aria-label="edit" onClick={incrementCount}>
            +
          </IconButton>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={1} p={1} mt={1}>
        <IconButton color="error" aria-label="edit" onClick={onRemove}>
          <DeleteIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl
          fullWidth>
          <InputLabel id="demo-simple-select-label">Bodega</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Bodega"
            defaultValue={wareHouse}
            onChange={(e) => onChange("wareHouse", e.target.value)}
          >
            {listBodega.map(item => (
              <MenuItem value={item._id} key={item._id}>{item.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          id="outlined-select-currency"
          select
          label="Estado"
          defaultValue={state}
          fullWidth={true}
          onChange={(e) => onChange("state", e.target.value)}
        >
          {optionState.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={5}>
        <TextField id="outlined-basic" label="Observaciones" variant="outlined" fullWidth={true} onChange={(e) => onChange("observations", e.target.value)} />
      </Grid>
      <br />
      <Divider />
      <br />
    </Grid>
  );
}

const StockRoom = () => {
  const navigate = useNavigate()
  const { id } = useParams();
  const [orden, setOrden] = useState([])
  const [rows, setRows] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [errors, setErrors] = useState([defaultStateError]);

  const [product, setProduct] = useState({});

  //Modal Agregar
  const [show, setShow] = useState(false);
  //Handle del modal add
  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    setProduct(item)
    const existeElemento = rows.some(row => row.name === item.name);
    if (existeElemento === false) {
      setRows(rows.concat({
        _id: uuidv4(),
        name: item.name,
        state: "Bien",
        serialNumber: "",
        observations: "",
        sku: item.sku,
        wareHouse: "",
        amount: 1,
      }));
    }
    setShow(true);
  };
  //Funcion para obtener los productos
  const getOrden = async () => {
    await api
      .get("/purchaseOrder/getPurshaseOrderById/" + id)
      .then((res) => {
        setOrden(res.data.purchaseOrder);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Efffect para obtener productos
  useEffect(() => {
    getOrden();
  }, [orden, id]);

  const handleOnChange = (idProductoEditar, name, value) => {
    console.log(rows)
    const copyRows = rows.map(producto => {
      if (producto._id === idProductoEditar) {
        return { ...producto, [name]: value };
      }
      return producto;
    });
    setRows(copyRows);
  };

  const handleOnAdd = () => {
    setRows(rows.concat({
      _id: uuidv4(),
      name: product.name,
      state: "Bien",
      serialNumber: "",
      observations: "",
      sku: product.sku,
      amount: 1,
      wareHouse: "",
    }));
  };

  const handleOnRemove = (indice) => {
    const copyRows = [...rows];
    const arrayFiltrado = copyRows.filter((producto) => { if (producto._id.toString() !== indice) { return producto } });
    setRows(arrayFiltrado);
  };

  const addProductToStockRoom = async () => {
    // Deshabilita el botón después de hacer clic
    setIsButtonDisabled(true)
    const nuevoProductos = rows.filter(row => row.name === product.name)
    const arrayNew = nuevoProductos.map(({ name, state, serialNumber, observations, sku, amount, wareHouse }) => ({ name, state, serialNumber, observations, sku, amount, wareHouse}));
    localStorage.setItem(product.name, JSON.stringify(nuevoProductos));
    purchaserOrderService.ingresBodega(id, arrayNew).then(() => {
      Swal.fire({
        icon: "success",
        title: "Realizado",
        showConfirmButton: false,
        timer: 1500,
      });
      const productosFiltrados = rows.filter(row => row.nombre !== product.name);
      setRows(productosFiltrados)
      handleClose();
    }
    )
    setIsButtonDisabled(false)
  };

  const breadcrumbs = [
    <Link underline="none" key="1" color="inherit" disable >
      smc
    </Link>,
    <Link underline="none" key="1" color="inherit"  >
      Ordenes de compra
    </Link>,
    <Typography key="3" color="text.primary">
      Ingreso de Bodega
    </Typography>,
  ];

  return (
    <React.Fragment>
      {/* Breadcrumbx */}
      <Box m="20px">
        <Stack spacing={2}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="INGRESO DE BODEGA" subtitle="Agrega los productos" />
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <span><strong>No. Orden de Compra:</strong> {orden.orderNumber}</span>
            </Grid>
            <Grid item xs={4}>
              <span><strong>Nombre del proveedor:</strong> {orden.supplier?.name}</span>
            </Grid>
            <Grid item xs={4}>
              <span>
                <strong>Fecha de pedido: </strong>{" "}
                {(new Date(orden.applicationDate).getMonth() > 8
                  ? new Date(orden.applicationDate).getMonth() + 1
                  : "0" + (new Date(orden.applicationDate).getMonth() + 1)) +
                  "/" +
                  (new Date(orden.applicationDate).getDate() > 9
                    ? new Date(orden.applicationDate).getDate()
                    : "0" + new Date(orden.applicationDate).getDate()) +
                  "/" +
                  new Date(orden.applicationDate).getFullYear()}
              </span>
            </Grid>
          </Grid>
          {/* <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Button variant="contained"
              disabled={disableButtonFinish}
              onClick={finish}
            >
              Finalizar
            </Button>
          </Box> */}
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell align="right">Cantidad</TableCell>
                <TableCell align="right">Por entregar</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!orden.products ? (
                <TableRow>
                  <TableCell colSpan={4}>No hay productos</TableCell>
                </TableRow>
              ) : (
                <>
                  {
                    orden.products.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="right">{row.amount}</TableCell>
                        <TableCell align="right">{row.toDilevery ? (
                          row.toDilevery
                        ) : (
                          <Badge bg="primary">Completado</Badge>
                        )}</TableCell>
                        <TableCell align="right"><Button variant="contained"
                          disabled={row.toDilevery ? false : true}
                          onClick={() => handleShow(row)}
                        >
                          Agregar
                        </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>
              <span>
                {product.name}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="mb-3">

            {rows
              .filter(row => row.name === product.name)
              .map((row, index) => (
                <RowForm
                  product={product}
                  {...row}
                  errors={errors}
                  onChange={(name, value) => handleOnChange(row._id, name, value)}
                  onRemove={() => handleOnRemove(row._id)}
                  key={index}
                />
              ))}
            <Button variant="contained" endIcon={<AddIcon />} onClick={handleOnAdd}>
              Añadir
            </Button>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button color="primary" onClick={addProductToStockRoom} disabled={isButtonDisabled}>
              Guardar
            </Button>
          </Modal.Footer>
        </Modal>
      </Box>
    </React.Fragment>
  )
}

export default StockRoom
