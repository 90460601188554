import React from 'react'
import { Box, Link, Button, Stack, Typography, Breadcrumbs } from '@mui/material';

//assets
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
//components
import Header from "../../components/header/Header";
import CollectionsTable from '../../components/table/CollectionsTable';
import ModalCreateCollection from '../../components/modal/ModalCreateCollection';



const Collections = () => {

    const breadcrumbs = [
        <Link underline="none" key="1" color="inherit" disable >
            Inventario
        </Link>,
        <Typography key="3" color="text.primary">
            Colecciones
        </Typography>,
    ];

    return (
        <React.Fragment>
            {/* Breadcrumbx */}
            <Box m="20px">
                <Stack spacing={2}>
                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                    >
                        {breadcrumbs}
                    </Breadcrumbs>
                </Stack>
                {/* HEADER */}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title="COLECCIONES" subtitle="Administra tus Colecciones" />
                    <Box>
                        <ModalCreateCollection />
                    </Box>
                </Box>
                <CollectionsTable />
            </Box>
        </React.Fragment>
    )
}

export default Collections

