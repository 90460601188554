import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from 'react-bootstrap/Table';

import EditableField from './EditableField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

class InvoiceItem extends React.Component {
    render() {
        var onItemizedItemEdit = this.props.onItemizedItemEdit;
        var currency = this.props.currency;
        var rowDel = this.props.onRowDel;
        var itemTable = this.props.items.map(function (item) {
            return (
                <ItemRow onItemizedItemEdit={onItemizedItemEdit} item={item} onDelEvent={rowDel.bind(this)} key={item.id} currency={currency} />
            )
        });
        return (
            <div>
                <Table>
                    <thead>
                        <tr>
                            <th>ITEM</th>
                            <th>QTY</th>
                            <th>PRICE</th>
                            <th className="text-center">ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        {itemTable}
                    </tbody>
                </Table>
                <Button variant="contained" onClick={this.props.onRowAdd} >Añadir Producto</Button>
            </div>
        );

    }

}
class ItemRow extends React.Component {
    onDelEvent() {
        this.props.onDelEvent(this.props.item);
    }
    render() {
        return (
            <tr>
                <td style={{ width: '100%' }}>
                    <EditableField
                        onItemizedItemEdit={this.props.onItemizedItemEdit}
                        cellData={{
                            type: "text",
                            name: "name",
                            placeholder: "Nombre",
                            value: this.props.item.name,
                            id: this.props.item.id,
                        }} />
                    <EditableField
                        onItemizedItemEdit={this.props.onItemizedItemEdit}
                        cellData={{
                            type: "text",
                            name: "description",
                            placeholder: "Descripción",
                            value: this.props.item.description,
                            id: this.props.item.id
                        }} />
                </td>
                <td style={{ minWidth: '70px' }}>
                    <EditableField
                        onItemizedItemEdit={this.props.onItemizedItemEdit}
                        cellData={{
                            type: "number",
                            name: "quantity",
                            min: 1,
                            step: "1",
                            value: this.props.item.quantity,
                            id: this.props.item.id,
                        }} />
                </td>
                <td style={{ minWidth: '130px' }}>
                    <EditableField
                        onItemizedItemEdit={this.props.onItemizedItemEdit}
                        cellData={{
                            leading: this.props.currency,
                            type: "number",
                            name: "price",
                            min: 1,
                            step: "0.01",
                            presicion: 2,
                            textAlign: "text-end",
                            value: this.props.item.price,
                            id: this.props.item.id,
                        }} />
                </td>
                <td className="text-center" style={{ minWidth: '50px' }}>
                    <IconButton aria-label="delete" onClick={this.onDelEvent.bind(this)} color="error">
                        <DeleteIcon fontSize="inherit" />
                    </IconButton>
                </td>
            </tr>
        );

    }

}

export default InvoiceItem;