import api from "../services/api";
import Swal from "sweetalert2";
import products from "./products";

const realizarPedido = async (supplier, fechaInicio, fechaFin, shippingMail,
    paymentConditions, placeDelivery, warranty, shippingAddress, notes, productos,
    total) => {
    let response = {}
    const usuario = JSON.parse(localStorage.getItem('user'))
    const nuevoPedido = {
        supplier: supplier,
        applicationDate: fechaInicio,
        deliveryDate: fechaFin,
        paymentConditions: paymentConditions,
        deliveryConditions: placeDelivery,
        warranty: warranty,
        shippingAddress: shippingAddress,
        deliveryMail: shippingMail,
        creator: usuario._id,
        notes: notes,
        products: productos,
        totalToPay: total
    }
    //Para raealizar el pedido
    await api.post('/purchaseOrder/create', nuevoPedido).then(async (res) => {
        response = res.data
        if (res.data.status) {
            //Refresacar la pagina
            //  getSuppliers();
            //Alerta de agregado
            Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 2000
            })
            //   window.location.replace('/listPurchaseOrder');
        } else {
            //logs de error al backend
            const mensaje = {
                message: "Error al realizar el pedido",
                level: 3
            }
            await api.post('/logger', mensaje)
            ///Mensaje de error
            Swal.fire({
                icon: 'error',
                title: res.data.message,
                showConfirmButton: false,
                timer: 2000
            })
        }
    }).catch(async (err) => {
        ///Mensaje de error
        Swal.fire({
            icon: 'error',
            title: err.response.data.message,
            showConfirmButton: false,
            timer: 2000
        })
    });
    return response;
}

const ingresBodega = async (id, nuevoProductos) => {
    let response = {}
    const usuario = JSON.parse(localStorage.getItem('user'))
    const stockRoom = {
        idPurchaseOrder: id,
        creator: usuario.id,
        namePersonDilevery: usuario.name,
        products: nuevoProductos,
    };
    await api
        .post("/stockRoom/create", stockRoom)
        .then((res) => {
            response = res.data;
            if (res.data.status) {
                Swal.fire({
                    icon: "success",
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
            nuevoProductos.forEach((newProduct) => {
                if(find("product", "sku", newProduct.sku)) {
                    products.createProduct("", newProduct.name, "", newProduct.sku, 0 , 0, newProduct.amount, "BODEGA", newProduct.observations)
                }
            })
        })
        .catch((err) => {
            Swal.fire({
                icon: "error",
                title: err.response.data.message,
                showConfirmButton: true,
            });
        });
    return response;
}
const find = async (database, type, value ) => {
    let salida = null;
    const body = {
        database: database,
        type: type,
        value: value
    };

    await api.posy('/global/query', body).then(res => {
        salida = res.data
    }).catch(err => console.log(err))

    return salida;
}
const eliminarOrden = async (id) => {
    let response = {}
    await api.delete('/purchaseOrder/delete/' + id).then(res => {
        response = res.data
    }).catch(err => console.log(err))
    return response;
}

const deleteStockroom = async (id) => {
    let response = {}
    await api.delete('/stockRoom/deleteProductsInStockRoom/' + id).then(res => {
        response = res.data
    }).catch(err => console.log(err))
    return response;
}
const updateStockroom = async (id, wareHouse, name, sku, amout, observations) => {
    let salida = {}
    const body = {
        database: "prosuctInStockRoom",
        id: id,
        warehouse: wareHouse
    };
    if(find("product", "sku", sku)) {
        products.createProduct("", name, "", sku, 0 , 0, amout, "BODEGA", observations)
    }
    await api.put('/global/', body).then(res => {
        salida = res.data
    }).catch(err => console.log(err))

    return salida;
}
export default { realizarPedido, ingresBodega, eliminarOrden, deleteStockroom, updateStockroom }