import React from 'react'
import { Box, Link, Button, Stack, Typography, Breadcrumbs, Grid } from '@mui/material';
//assets
import ShowChartIcon from '@mui/icons-material/ShowChart';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DateRangeIcon from '@mui/icons-material/DateRange';
//components
import Header from "../../components/header/Header";
import ABCClasificationTable from '../../components/table/ABCClasificationTable';
import StatisticsCard from '../../components/card/StatisticsCard';
import CardStatisticsVerticalComponent from '../../components/card/CardStatisticsVerticalComponent';
import api from "../../services/api"
import abcclasification from '../../services/abcclasification';
import CreateClasification from '../../sections/abcclasification/CreateClasification';

const ABCClassification = () => {
    const [tipoClasificacion, setTipoClasificacion] = React.useState("Costo Unitario")
    const [frecuenciaActualizacion, setFrecuenciaActualizacion] = React.useState("diariamente")
    const [valueA, setValueA] = React.useState(20)
    const [valueB, setValueB] = React.useState(20)
    const [valueC, setValueC] = React.useState(60)
    const [porcentajeA, setPorcentajeA] = React.useState(20)
    const [porcentajeB, setPorcentajeB] = React.useState(20)
    const [porcentajeC, setPorcentajeC] = React.useState(60)
    const [listaProductos, setListaProductos] = React.useState([])
    const [configuracionId, setConfiguracionId] = React.useState("")
    const [configuracionTipo, setConfiguracionTipo] = React.useState("Ninguna")
    const [configuracionFrecuencia, setConfiguracionFrecuencia] = React.useState("Ninguna")
    const [fechaActualizacion, setFechaActualizacion] = React.useState("Ninguna")
    const [fechaCreacion, setFechaCreacion] = React.useState("Ninguna")
    const [settings, setSettings] = React.useState({});
    const fetchData = async () => {
        await api.get('/configuracion/').then(res => {
            console.log(res.data[0])
            setSettings(res.data[0])

        }).catch(error => console.log(error))
    }
    React.useEffect(() => {
        fetchData();
    }, []);


    const generateClasification = () => {
        const data = JSON.parse(localStorage.getItem('user'))
        const response = abcclasification.generateClasification(data.id);
        console.log(response)
    }

    const breadcrumbs = [
        <Link underline="none" key="1" color="inherit" disable >
            Estudio
        </Link>,
        <Typography key="3" color="text.primary">
            Proveedores
        </Typography>,
    ];

    return (
        <React.Fragment>
            {/* Breadcrumbx */}
            <Box m="20px">
                <Stack spacing={2}>
                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                    >
                        {breadcrumbs}
                    </Breadcrumbs>
                </Stack>
                {/* HEADER */}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title="CLASIFICACIÓN ABC" subtitle="" />
                    <Box>
                        <Button variant="contained" onClick={generateClasification}>
                            Refrescar
                        </Button>
                        {" "}
                        <CreateClasification />
                    </Box>
                </Box>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={5} lg={7}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <CardStatisticsVerticalComponent
                                    stats={settings.type}
                                    color='success'
                                    title='Tipo de clasficación:'
                                    icon={<ShowChartIcon />}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CardStatisticsVerticalComponent
                                    stats={settings.frequencyUpdateClassification}
                                    color='secondary'
                                    title='Frecuencia:'
                                    icon={<ShowChartIcon />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <StatisticsCard a={settings.percentageA} b={settings.percentageB} c={settings.percentageC}/>
                            </Grid>
                            <Grid item xs={6}>
                                <CardStatisticsVerticalComponent
                                    stats={settings.creationDate}
                                    title='Fecha de creación::'
                                    icon={<DateRangeIcon />}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CardStatisticsVerticalComponent
                                    stats={settings.creationDate}
                                    color='warning'
                                    title='Fecha de actualización:'
                                    icon={<DateRangeIcon />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={7} lg={5}>
                        <ABCClasificationTable />
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    )
}

export default ABCClassification