import api from "../services/api";
import Swal from "sweetalert2";

const productsAlert = async clasificacion => {
    await api.get('/products/getProductsByAlertAndClasification/' + clasificacion).then(res => {
        return res.data;
    }).catch(err => {
        console.log(err)
    })
}
const getProduct = async id => {
    let salida = {}
    await api.get('/products/' + id).then(async res => {
        salida = res.data;
    }).catch(err => {
        console.log(err)
    })
    return salida;
}
const createProduct = async (image, name, partNumber, sku,  cost, price, stock, colecciones, observaciones) => {
    let response = {};
    const usuario = JSON.parse(localStorage.getItem('user'))
    let formData = new FormData()
    formData.append('image', image)
    formData.append('name', name)
    formData.append('partNumber', partNumber)
    formData.append('sku', sku)
    formData.append('cost', cost)
    formData.append('price', price)
    formData.append('stock', stock )
    formData.append('coleccion', colecciones)
    formData.append('observaciones', observaciones)
    formData.append('creator', usuario.id)
    await api.post('/products/createProduct', formData).then(async res => response = res.data)
    return response
}
const editProduct = async (id, image, name, partNumber, sku, cost, price, stock, colecciones, observaciones) => {
    let formData = new FormData()
    formData.append('image', image)
    formData.append('name', name)
    formData.append('partNumber', partNumber)
    formData.append('sku', sku)
    formData.append('cost', cost)
    formData.append('price', price)
    formData.append('stock', stock)
    formData.append('coleccion', colecciones)
    formData.append('observaciones', observaciones)
    const response = await api.put('/products/updateProduct/' + id, formData).then(async (res) => {
        if (res.data.status) {
            //Alerta de actualizado
            Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 2000
            })
        } else {
            //logs de error
            const mensaje = {
                message: "Error al actualizar un producto",
                level: 3
            }
            await api.post('/logger', mensaje)
            //Alerta de actualizado
            Swal.fire({
                icon: 'error',
                title: res.data.message,
                showConfirmButton: false,
                timer: 2000
            })
        }
    }).catch((err) => {
        console.log(err)
        Swal.fire({
            icon: 'error',
            title: err.response.data.message || "Error al actualizar",
            showConfirmButton: false,
            timer: 2000
        })
    })
    return response
}
//Funcion para eliminar un producto 
const deleteProduct = async (id) => {
    let salida = {};
    await api.delete('/products/deleteProduct/' + id).then(async res => {
        salida = res.data;
        if (res.data.status) {
            //Alerta de editado
            Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 2000
            })
        } else {
            //logs de error
            const mensaje = {
                message: "Error al eliminar un producto",
                level: 3
            }
            await api.post('/logger', mensaje)
            ///Mensaje de error
            Swal.fire({
                icon: 'error',
                title: res.data.message,
                showConfirmButton: false,
                timer: 2000
            })
        }
    })
    return salida;
}

const selectFile = async (file, idUsuario) => {
    const formData = new FormData();
    formData.append("xlsx", file);
    Swal.fire({
        position: 'center',
        title: 'Espera un momento...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
            Swal.showLoading()
        },
    })
    await api.post('/products/upload/', formData, {
        headers: {
            'id': idUsuario
        }
    }).then(async (response) => {
        Swal.close()
        if (response.data.status) {
            //Alerta de editado
            Swal.fire({
                icon: 'success',
                title: response.data.message,
                showConfirmButton: true
            })
        }
    }).catch(async (err) => {
        Swal.close()
        if (err.response.status !== 500) {
            Swal.fire({
                icon: 'error',
                title: err.response.data.message,
                showConfirmButton: true
            })
        } else {
            //logs de error
            const mensaje = {
                message: "Error al agregar un producto a partir de un archivo",
                level: 3
            };
            await api.post('/logger', mensaje);
            ///Mensaje de error
            Swal.fire({
                icon: 'error',
                title: mensaje.message,
                showConfirmButton: true
            })
        }
    })
}

const colocarDemanda = async (idProducto, demanda) => {
    let salida = {}
    const demandaProducto = {
        demandaAnualPromedio: demanda
    };
    await api.put('/products/putDemanda/' + idProducto, demandaProducto)
        .then(async response => {
            if (response.data.status) {
                //alerta
                Swal.fire({
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 2000
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: response.data.message,
                    showConfirmButton: true
                })
            }
            salida = response.data;

        })
    return salida;
}
export default { productsAlert, createProduct, deleteProduct, editProduct, selectFile, colocarDemanda, getProduct }