import React, { useContext } from 'react'
import { Box, Link, Button, Stack, Typography, Breadcrumbs, IconButton } from '@mui/material';
import Fab from '@mui/material/Fab';
import CircularProgress from '@mui/material/CircularProgress';

//assets
import FileUploadIcon from '@mui/icons-material/FileUpload';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CheckIcon from '@mui/icons-material/Check';

//components
import Header from "../../components/header/Header";
import ProductosTable from '../../components/table/ProductosTable';
import ModalCreateProduct from '../../components/modal/ModalCreateProduct';
import products from '../../services/products';
import InventoryTable from '../../components/table/InventoryTable';


const Inventory = () => {

    const breadcrumbs = [
        <Link underline="none" key="1" color="inherit" disable >
            scm
        </Link>,
        <Typography key="3" color="text.primary">
            Inventario
        </Typography>,
    ];

    return (
        <React.Fragment>
            {/* Breadcrumbx */}
            <Box m="20px">
                <Stack spacing={2}>
                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                    >
                        {breadcrumbs}
                    </Breadcrumbs>
                </Stack>
                {/* HEADER */}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title="INVENTARIO" subtitle="Visualiza los productos ingresados" />
                </Box>
                <InventoryTable />
            </Box>
        </React.Fragment>
    )
}

export default Inventory
