import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { Container, Grid, Box, Typography, TextField, FormControlLabel, Checkbox, Button, Link } from '@mui/material';
import axios from "axios";
import { useNavigate } from "react-router-dom";
//assets
import logo from '../../../assets/images/Artboard_2_5-100.png'
//services
import loginService from "../../../services/login";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://scandere.net/">
                Scandere
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const Login = () => {
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const formDataJSON = Object.fromEntries(data);
        const btnPointer = document.querySelector('#login-btn');
        btnPointer.innerHTML = 'Espere por favor...';
        btnPointer.setAttribute('disabled', true);
        loginService.login(formDataJSON).then((response) => {
            btnPointer.innerHTML = 'Ingresar';
            btnPointer.removeAttribute('disabled');
            const token = response.token;
            if (!token) {
                if(response.status){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.status
                    })
                }else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Incapaz de iniciar sesión. Inténtalo después.'
                    })
                }
                return;
            }
            localStorage.clear();
            localStorage.setItem('user-token', token);
            localStorage.setItem('refreshToken', response.refreshToken);
            localStorage.setItem('user', JSON.stringify(response));
            navigate('/scm/products');
        }).catch((error) => {
            const errorMessage = error.response?.data.message;
            btnPointer.innerHTML = 'Ingresar';
            btnPointer.removeAttribute('disabled');
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: errorMessage || ''
            })
        });
    };

    return (
        <Container component="main" maxWidth="xs" >
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box
                    component="img"
                    sx={{
                        height: 75,
                        width: 250,
                        maxHeight: { xs: 233, md: 167 },
                        maxWidth: { xs: 350, md: 250 },
                    }}
                    alt="Logo"
                    src={logo}
                />
                <br />
                <Typography component="h1" variant="h5">
                    Ingresar
                </Typography>
                <Box component="form"
                    onSubmit={handleSubmit}
                //noValidate sx={{ mt: 1 }}
                >
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Correo electrónico"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Contraseña"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Recuerdame"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        id="login-btn"
                    >
                        Ingresar
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="forgetPassword" variant="body2">
                                Olvidó su contraseña
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
    )
}

export default Login
