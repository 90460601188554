import React, { useEffect, useState } from 'react'
import { Box, Link, Button, Stack, Typography, Breadcrumbs, Grid } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import { useNavigate } from "react-router-dom";

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import { Formik } from 'formik';
//assets
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

//components
import Header from "../../components/header/Header";
import { RoleDetailes } from '../../sections/role/RoleDetailes';

import api from "../../services/api"
import Swal from 'sweetalert2';

const style = {
    width: '100%',
    bgcolor: 'background.paper',
};

const AdminRole = () => {
    const navigate = useNavigate()
    //Valida el formulario
    const [validated, setValidated] = useState(false);
    const [listaPerfiles, setListaPerfiles] = useState([])
    const [idPerfil, setIdPerfil] = useState("")
    const [name, setName] = useState("")
    const [idPermiso, setIdPermiso] = useState("")
    const [idPermisoColecciones, setIdPermisoColecciones] = useState("")
    const [idPermisoClasificacionABC, setIdPermisoClasificacionABC] = useState("")
    const [idPermisoEstudioProducto, setIdPermisoEstudioProducto] = useState("")
    const [listaPermisos, setlistaPermisos] = useState([])
    const [product, setProduct] = useState(0)
    const [colecciones, setColecciones] = useState(0)
    const [clasificacionABC, setClasificacionABC] = useState(0)
    const [estudioProducto, setEstudioProducto] = useState(0)
    const [showPanel, setShowPanel] = useState(false)
    //Modal Agregar
    const [show, setShow] = useState(false);
    //Handle del modal add
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShowPanel(false)
        setName("")
        setValidated(false)
        setShow(true)
    };

    //Efffect para obtener perfiles al iniciar la interfaz
    useEffect(() => {
        getPerfiles()
    }, [])
    //Funcion para listar los productos
    const getPerfiles = async () => {
        const res = await api.get('/perfil/')
        setListaPerfiles(res.data)
    }
    //Funcion para listar los permisos
    const getPermisos = async (item) => {
        const res = await api.get('/permisos/' + item._id)
        setlistaPermisos(res.data)
        setIdPermiso(res.data[0]._id)
        setIdPermisoColecciones(res.data[1]._id)
        setIdPermisoClasificacionABC(res.data[2]._id)
        setIdPermisoEstudioProducto(res.data[3]._id)
        setName(item.name)
        setIdPerfil(item._id)
        setProduct(res.data[0].permisos)
        setColecciones(res.data[1].permisos)
        setClasificacionABC(res.data[2].permisos)
        setEstudioProducto(res.data[3].permisos)
        setShowPanel(true)
    }
    //Funcion para agregar los productos 
    const addPerfil = async () => {
        setIdPerfil("")
        let obj = { name }
        const res = await api.post('/perfil/createPerfil', obj)
        const mensaje = res.data.message
        if (!res.data.status) {
            //logs de error al backend
            const mensaje = {
                message: "Error al agregar un perfil",
                level: 3
            }
            await api.post('/logger', mensaje)
            ///Mensaje de error
            Swal.fire({
                icon: 'error',
                title: mensaje,
                showConfirmButton: false,
                timer: 2000
            })
        } else {
            //Alerta
            Swal.fire({
                icon: 'success',
                title: mensaje,
                showConfirmButton: false,
                timer: 2000
            })
            //Refresacar la pagina
            getPerfiles()
            //Alerta de agregado
            Swal.fire({
                icon: 'success',
                title: mensaje,
                showConfirmButton: false,
                timer: 2000
            })
            //Para cerrar el modal
            setShow(false)
        }
    }
    const deletePermiso = async () => {
        Swal.fire({
            title: '¿Estás seguro de eliminar el perfil?',
            text: "¡No podrás revertirlo!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await api.delete('/perfil/deletePerfil/' + idPerfil)
                const msg = res.data.message
                if (res.data.statu) {
                    //logs de error al backend
                    const mensaje = {
                        message: "Error al eliminar un permiso",
                        level: 3
                    }
                    await api.post('/logger', mensaje)
                    //Alerta de eliminador un permiso
                    Swal.fire({
                        icon: 'error',
                        title: msg,
                        showConfirmButton: false,
                        timer: 2000
                    })
                } else {
                    //Refresacar la pagina
                    getPerfiles()
                    //
                    setIdPerfil("")
                    //Alerta de editado
                    Swal.fire({
                        icon: 'success',
                        title: msg,
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            }
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const nuevoPermiso = {
            permisos: product
        }
        await api.put('/permisos/updatePermisos/' + idPermiso, nuevoPermiso)
        const nuevoPermisoColecciones = {
            permisos: colecciones
        }
        await api.put('/permisos/updatePermisos/' + idPermisoColecciones, nuevoPermisoColecciones)
        const nuevoPermisoClasificacionABC = {
            permisos: clasificacionABC
        }
        const resClas = await api.put('/permisos/updatePermisos/' + idPermisoClasificacionABC, nuevoPermisoClasificacionABC)

        const nuevoPermisoEstudioProducto = {
            permisos: estudioProducto
        }
        const resEstProd = await api.put('/permisos/updatePermisos/' + idPermisoEstudioProducto, nuevoPermisoEstudioProducto)

        const msg = resEstProd.data.message
        if (!resEstProd.data.status) {
            //Alerta de advertencia
            Swal.fire({
                icon: 'error',
                title: msg,
                showConfirmButton: false,
                timer: 2000
            })
        } else {
            if (!resClas.data.status) {
                //logs de error al backend
                const mensaje = {
                    message: "Error al actualizar un perfil",
                    level: 3
                }
                await api.post('/logger', mensaje)
                ///Mensaje de error
                Swal.fire({
                    icon: 'error',
                    title: msg,
                    showConfirmButton: false,
                    timer: 2000
                })
            } else {
                //Alerta de editado
                Swal.fire({
                    icon: 'success',
                    title: msg,
                    showConfirmButton: false,
                    timer: 2000
                }).then(
                    //Refresacar la pagina
                    getPermisos()
                )
            }
        }
    };
    const cerrarLista = () => {
        setShowPanel(false)
    }
    const breadcrumbs = [
        <Link underline="none" key="1" color="inherit" disable >
            Perfil
        </Link>,
        <Link underline="none" key="1" color="inherit" onClick={() => navigate("/profile")} >
            Usuario
        </Link>,
        <Link underline="none" key="1" color="inherit" onClick={() => navigate("/profile/adminUsers")} >
            Administrar Usuario
        </Link>,
        <Typography key="3" color="text.primary" >
            Administrar Permisos
        </Typography>,
    ];

    return (
        <React.Fragment>
            {/* Breadcrumbx */}
            <Box m="20px">
                <Stack spacing={2}>
                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                    >
                        {breadcrumbs}
                    </Breadcrumbs>
                </Stack>
                {/* HEADER */}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title="Perfiles" subtitle="Administra los permisos de tus perfiles" />
                    <Box>
                        <Button variant="contained" onClick={handleShow}>
                            <AddIcon />
                            Nuevo Perfil
                        </Button>
                    </Box>
                </Box>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={7} lg={5}>
                        <Box sx={{ width: '100%' }}>
                            <Typography sx={{ fontWeight: 300, fontSize: '1.25rem', lineHeight: 1.72, letterSpacing: '0.22px' }}>
                                Perfiles
                            </Typography>
                            <Stack spacing={2}>
                                <List sx={style} component="nav" aria-label="mailbox folders">
                                    {listaPerfiles.map(item => (
                                        <ListItem button key={item._id} onClick={() => getPermisos(item)}>
                                            <ListItemText primary={item.name} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Stack>
                        </Box>
                    </Grid>
                    {showPanel && (
                        <Grid Grid item xs={12} md={5} lg={7}>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group as={Row} className="mb-3" controlId="formHorizontal">
                                    <Form.Label column sm={5}>
                                        {listaPermisos[0].name}
                                    </Form.Label>
                                    <Col sm={7} >
                                        <Form.Check
                                            inline
                                            label="Ver"
                                            name="group1"
                                            type='checkbox'
                                            id="Products-Ver"
                                            checked={product >= 4 ? true : false}
                                            onChange={() => { product >= 4 ? setProduct(product - 4) : setProduct(product + 4) }}
                                        />
                                        <Form.Check
                                            inline
                                            label="Crear/Editar"
                                            name="group1"
                                            type='checkbox'
                                            id="Products-Crear/Editar"
                                            checked={product === 2 || product === 3 || product > 5 ? true : false}
                                            onChange={() => { product === 2 || product === 3 || product > 5 ? setProduct(product - 2) : setProduct(product + 2) }}
                                        />
                                        <Form.Check
                                            inline
                                            label="Eliminar"
                                            name="group1"
                                            type='checkbox'
                                            id="Products-Eliminar"
                                            checked={product === 1 || product === 3 || product === 5 || product === 7 ? true : false}
                                            onChange={() => { product === 1 || product === 3 || product === 5 || product === 7 ? setProduct(product - 1) : setProduct(product + 1) }}
                                        />
                                        {/*
                <Form.Check
                    inline
                    label="Privilegios"
                    name="group1"
                    type='checkbox'
                    id="Products-Privilegios"
                />
                 */}

                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="formHorizontal">
                                    <Form.Label column sm={5}>
                                        {listaPermisos[1].name}
                                    </Form.Label>
                                    <Col sm={7} >
                                        <Form.Check
                                            inline
                                            label="Ver"
                                            name="group2"
                                            type='checkbox'
                                            id="Colecciones-Ver"
                                            checked={colecciones >= 4 ? true : false}
                                            onChange={() => { colecciones >= 4 ? setColecciones(colecciones - 4) : setColecciones(colecciones + 4) }}
                                        />
                                        <Form.Check
                                            inline
                                            label="Crear/Editar"
                                            name="group2"
                                            type='checkbox'
                                            id="Colecciones-Crear/Editar"
                                            checked={colecciones === 2 || colecciones === 3 || colecciones > 5 ? true : false}
                                            onChange={() => { colecciones === 2 || colecciones === 3 || colecciones > 5 ? setColecciones(colecciones - 2) : setColecciones(colecciones + 2) }}
                                        />
                                        <Form.Check
                                            inline
                                            label="Eliminar"
                                            name="group2"
                                            type='checkbox'
                                            id="Colecciones-Eliminar"
                                            checked={colecciones === 1 || colecciones === 3 || colecciones === 5 || colecciones === 7 ? true : false}
                                            onChange={() => { colecciones === 1 || colecciones === 3 || colecciones === 5 || colecciones === 7 ? setColecciones(colecciones - 1) : setColecciones(colecciones + 1) }}
                                        />
                                        {/*
                <Form.Check
                    inline
                    label="Privilegios"
                    name="group2"
                    type='checkbox'
                    id="Products-Privilegios"
                />
                 */}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="formHorizontal">
                                    <Form.Label column sm={5}>
                                        Clasificación ABC
                                    </Form.Label>
                                    <Col sm={7} >
                                        <Form.Check
                                            inline
                                            label="Ver"
                                            name="group2"
                                            type='checkbox'
                                            id="ClasificacionABC-Ver"
                                            checked={clasificacionABC === 1 || clasificacionABC === 2 ? true : false}
                                            onChange={() => { clasificacionABC === 1 ? setClasificacionABC(clasificacionABC - 1) : clasificacionABC === 2 ? setClasificacionABC(clasificacionABC - 2) : setClasificacionABC(clasificacionABC + 1) }}
                                        />
                                        <Form.Check
                                            inline
                                            label="Administrar"
                                            name="group2"
                                            type='checkbox'
                                            id="ClasificacionABC-Crear/Editar"
                                            checked={clasificacionABC === 2 ? true : false}
                                            onChange={() => { clasificacionABC === 2 ? setClasificacionABC(clasificacionABC - 1) : clasificacionABC === 0 ? setClasificacionABC(clasificacionABC + 2) : setClasificacionABC(clasificacionABC + 1) }}
                                        />
                                        {/*
                <Form.Check
                    inline
                    label="Privilegios"
                    name="group2"
                    type='checkbox'
                    id="Products-Privilegios"
                />
                 */}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="formHorizontal">
                                    <Form.Label column sm={5}>
                                        Estudio de Productos
                                    </Form.Label>
                                    <Col sm={7} >
                                        <Form.Check
                                            inline
                                            label="Ver"
                                            name="group2"
                                            type='checkbox'
                                            id="EstudioProducto-Ver"
                                            checked={estudioProducto === 1 || estudioProducto === 2 ? true : false}
                                            onChange={() => { estudioProducto === 1 ? setEstudioProducto(estudioProducto - 1) : estudioProducto === 2 ? setEstudioProducto(estudioProducto - 2) : setEstudioProducto(estudioProducto + 1) }}
                                        />
                                        <Form.Check
                                            inline
                                            label="Administrar"
                                            name="group2"
                                            type='checkbox'
                                            id="ClasificacionABC-Crear/Editar"
                                            checked={estudioProducto === 2 ? true : false}
                                            onChange={() => { estudioProducto === 2 ? setEstudioProducto(estudioProducto - 1) : estudioProducto === 0 ? setEstudioProducto(estudioProducto + 2) : setEstudioProducto(estudioProducto + 1) }}
                                        />
                                        {/*
                <Form.Check
                    inline
                    label="Privilegios"
                    name="group2"
                    type='checkbox'
                    id="Products-Privilegios"
                />
                 */}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Col sm={{ span: 7, offset: 5 }}>
                                        <Button type="submit" variant="contained">Guardar Cambios</Button>
                                    </Col>
                                </Form.Group>
                            </Form>
                            <Button type="submit" variant="contained"  onClick={deletePermiso}>Eliminar Perfil</Button>
                        </Grid>
                    )}
                </Grid>
            </Box>
            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title><span>Añadir perfil</span></Modal.Title>
                </Modal.Header>
                <Formik
                    validate={() => {
                        setValidated(false)
                        let errores = {};
                        // Validacion nombre
                        if (!name) {
                            errores.name = '*Por favor ingresa una nombre. Campo obligatorio*'
                        } else if (!/^[\wÀ-ÿ\s,"-.:-;+*~´°!"#$%&/()=?|¬¨^`<>]{1,100}$/.test(name)) {
                            errores.name = 'El nombre no es correcto, no puede sobre pasar los 100 caracteres'
                        }
                        if (!errores.name) {
                            setValidated(true)
                        }
                        return errores;
                    }}
                >
                    {({
                        handleBlur,
                        touched,
                        errors,
                    }) => (
                        <Form >
                            <Modal.Body>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" >
                                        <Form.Label>Nombre del perfil</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="name"
                                            name="name"
                                            placeholder="Nombre del perfil"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.name && !errors.name}
                                            isInvalid={!!errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outlined"onClick={handleClose}>
                                    Cancelar
                                </Button>
                                {" "}
                                <Button variant="contained" onClick={addPerfil} disabled={!validated}>
                                    Guardar
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </React.Fragment >
    )
}

export default AdminRole