import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '@mui/material/Button';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import SendIcon from '@mui/icons-material/Send';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf'

function GenerateInvoice() {
    html2canvas(document.querySelector("#invoiceCapture")).then((canvas) => {
        const imgData = canvas.toDataURL('image/png', 1.0);
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'pt',
            format: [612, 792]
        });
        pdf.internal.scaleFactor = 1;
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('invoice-001.pdf');
    });
}

class InvoiceModal extends React.Component {
    constructor(props) {
        console.log("props", props)
        super(props);
    }
    render() {
        return (
            <div>
                <Modal show={this.props.showModal} onHide={this.props.closeModal} size="lg" centered>
                    <div id="invoiceCapture">
                        <div className="d-flex flex-row justify-content-between align-items-start bg-light w-100 p-4">
                            <div className="w-100">
                                <h4 className="fw-bold my-2">ORDEN DE COMPRA </h4>
                                <h6 className="fw-bold text-secondary mb-1">
                                    Orden #: {this.props.info.orderNumber || ''}
                                </h6>
                            </div>
                            <div className="text-end ms-4">
                                <h6 className="fw-bold mt-1 mb-2">Total de &nbsp;Pago:</h6>
                                <h5 className="fw-bold text-secondary">$ {this.props.info.totalToPay}</h5>
                            </div>
                        </div>
                        <div className="p-4">
                            <Row className="mb-4">
                                <div className="fw-bold">Condiciones de Aquisición:</div>
                                <div ><span className="fw-bold mt-2">Proveedor: </span>{this.props.info.supplier?.name}</div>
                                <div ><span className="fw-bold mt-2">Fecha de Solicitud: </span>
                                    {(new Date(this.props.info.applicationDate).getMonth() > 8
                                        ? new Date(this.props.info.applicationDate).getMonth() + 1
                                        : "0" + (new Date(this.props.info.applicationDate).getMonth() + 1)) +
                                        "-" +
                                        (new Date(this.props.info.applicationDate).getDate() > 9
                                            ? new Date(this.props.info.applicationDate).getDate()
                                            : "0" + new Date(this.props.info.applicationDate).getDate()) +
                                        "-" +
                                        new Date(this.props.info.applicationDate).getFullYear()}
                                </div>
                                <div ><span className="fw-bold mt-2">Fecha de Entrega: </span>
                                    {(new Date(this.props.info.deliveryDate).getMonth() > 8
                                        ? new Date(this.props.info.deliveryDate).getMonth() + 1
                                        : "0" + (new Date(this.props.info.deliveryDate).getMonth() + 1)) +
                                        "-" +
                                        (new Date(this.props.info.deliveryDate).getDate() > 9
                                            ? new Date(this.props.info.deliveryDate).getDate()
                                            : "0" + new Date(this.props.info.deliveryDate).getDate()) +
                                        "-" +
                                        new Date(this.props.info.deliveryDate).getFullYear()}
                                </div>
                                <div ><span className="fw-bold mt-2">Condicones de Pago: </span>{this.props.info.paymentConditions}</div>
                                <div ><span className="fw-bold mt-2">Condicones de Entrega: </span>{this.props.info.deliveryConditions}</div>
                                <div ><span className="fw-bold mt-2">Garantía(meses): </span>{this.props.info.warranty}</div>
                                <div ><span className="fw-bold mt-2">Dirección de Envío: </span>{this.props.info.deliveryMail}</div>
                                <div ><span className="fw-bold mt-2">Correo de Envío: </span>{this.props.info.shippingAddress}</div>
                                <div ><span className="fw-bold mt-2">Notas: </span>{this.props.info.notes}</div>
                            </Row>
                            <Table className="mb-0">
                                <thead>
                                    <tr>
                                        <th>Cantidad</th>
                                        <th>DESCRIPCIÓN</th>
                                        <th>No PARTE</th>
                                        <th className="text-end">PRECIO UNITARO</th>
                                        <th className="text-end">PRECIO TOTAL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.productos?.map((item, index) => {
                                        <tr key={index}>
                                            <td style={{ width: '70px' }}>
                                                {item.amount}
                                            </td>
                                            <td>
                                                nn
                                            </td>
                                            <td>
                                            {item.name}
                                            </td>
                                            <td className="text-end" style={{ width: '100px' }}>{item.unitPrice}</td>
                                            <td className="text-end" style={{ width: '100px' }}>{item.unitPrice * item.amount}</td>
                                        </tr>
                                    })}

                                </tbody>
                            </Table>
                            <Table>
                                <tbody>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr className="text-end">
                                        <td></td>
                                        <td className="fw-bold" style={{ width: '100px' }}>SUBTOTAL</td>
                                        <td className="text-end" style={{ width: '100px' }}>{this.props.currency} {this.props.subTotal}</td>
                                    </tr>
                                    {this.props.taxAmmount !== 0.00 &&
                                        <tr className="text-end">
                                            <td></td>
                                            <td className="fw-bold" style={{ width: '100px' }}>IVA</td>
                                            <td className="text-end" style={{ width: '100px' }}>{this.props.currency} {this.props.taxAmmount}</td>
                                        </tr>
                                    }
                                    {this.props.discountAmmount !== 0.00 &&
                                        <tr className="text-end">
                                            <td></td>
                                            <td className="fw-bold" style={{ width: '100px' }}>DESCUENTO</td>
                                            <td className="text-end" style={{ width: '100px' }}>{this.props.currency} {this.props.discountAmmount}</td>
                                        </tr>
                                    }
                                    <tr className="text-end">
                                        <td></td>
                                        <td className="fw-bold" style={{ width: '100px' }}>TOTAL</td>
                                        <td className="text-end" style={{ width: '100px' }}>{this.props.currency} {this.props.total}</td>
                                    </tr>
                                </tbody>
                            </Table>
                            {this.props.info.notes &&
                                <div className="bg-light py-3 px-4 rounded">
                                    {this.props.info.notes}
                                </div>}
                        </div>
                    </div>
                    <div className="pb-4 px-4">
                        <Row>
                            {/*
                            <Col md={6}>
                                <Button variant="contained" endIcon={<SendIcon />} onClick={GenerateInvoice}>
                                    ENVIAR
                                </Button>
                            </Col>
                            */}
                            
                            <Col md={6}>
                                <Button variant="contained" endIcon={<DownloadForOfflineIcon />} onClick={GenerateInvoice}>
                                    DESCARGAR
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>
                <hr className="mt-4 mb-3" />
            </div>
        )
    }
}

export default InvoiceModal;