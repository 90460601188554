import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import { FormControl, FormLabel, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
//assets
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
//swal
import Swal from 'sweetalert2';
//services
import UserContext from '../../services/userProvider';
import api from "../../services/api"
import collections from '../../services/collections';

import { useNavigate } from "react-router-dom";
import userService from '../../services/userService';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ModalCreateUser = () => {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [image, setImage] = React.useState({ preview: '', data: '', valido: true })
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [role, setRole] = React.useState("")
    const [perfil, setPerfil] = React.useState("Administrador")
    const [listPerfiles, setlistPerfiles] = React.useState([])

    const fetchData = async () => {
        await api.get('/perfil/').then(res => {
            setlistPerfiles(res.data.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                return 0;
            }));
        }).catch(error => console.log(error))
    }

    React.useEffect(() => {
        fetchData();
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleSubmit = () => {
        setOpen(false);
    };
    //Funcion que valida la extension del archivo y almmacena su estado
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        let valido = true
        switch (file.type) {
            case 'image/png':
                valido = true
                break;
            case 'image/jpg':
                valido = true
                break;
            case 'image/jpeg':
                valido = true
                break;
            case '':
                valido = true
                break;
            default:
                valido = false
                ///Mensaje de error
                Swal.fire({
                    icon: 'error',
                    title: "Formato de la imagen no válido. Solo .jpeg, .jpg y .png",
                    showConfirmButton: false,
                    timer: 2000
                })
        }
        if (valido) {
            const img = {
                preview: URL.createObjectURL(file),
                data: file,
                valido: valido
            }
            setImage(img)
        }
    }
    const crearUsario = async () => {
        const response = userService.createUsuario(image.data, name, email, password, perfil, role).then(() => {
            handleClose()
            if (response.status === true) {
                navigate(0);
            }
        }
        )
    }
    return (
        <>
            <Button variant="contained" onClick={handleClickOpen}>
                <AddIcon />
                Nuevo Usuario
            </Button>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Nueva Usuario
                        </Typography>
                        <Button autoFocus variant="outlined" color="outlined" onClick={crearUsario}>
                            Guardar
                        </Button>
                    </Toolbar>
                </AppBar>
                <Box
                    component="form"
                    sx={{
                        alignItems: 'center',
                        '& > :not(style)': { m: 1 },
                    }}
                    autoComplete="off"
                    onSubmit={handleSubmit}
                >
                    <div>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            Imagen de la Colección:
                            {image.preview && <img src={image.preview} alt="imagen de coleccion" width='100' height='100' />}
                            <IconButton color="primary" aria-label="upload picture" component="label">
                                <input hidden accept="image/png, image/jpeg, image/jpg" onChange={handleFileChange} type="file" />
                                <PhotoCamera />
                            </IconButton>
                        </Stack>
                        <TextField fullWidth label="Nombre de Usuario" id="name" margin="normal" required onChange={(e) => setName(e.target.value)} />
                        <TextField
                            margin="normal"
                            id="standard-multiline-static"
                            label="Email"
                            type='email'
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                        />
                        <TextField
                            margin="normal"
                            id="standard-multiline-static"
                            label="Contraseña"
                            type='password'
                            onChange={(e) => setPassword(e.target.value)}
                            fullWidth
                        />
                        <FormControl
                            margin="normal"
                            fullWidth
                            sx={{ marginLeft: 1, width: '42ch' }}>
                            <InputLabel id="demo-simple-select-label">Perfil</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={perfil}
                                defaultValue={perfil}
                                label="Perfil"
                                onChange={(value) => setPerfil(value)}
                            >
                                {listPerfiles.map(item => (
                                    <MenuItem value={item.name} key={item._id}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField fullWidth label="Role" id="name" margin="normal" required onChange={(e) => setRole(e.target.value)} />
                    </div>
                </Box>
            </Dialog>
        </>
    );
}

export default ModalCreateUser
