import React, { useEffect } from 'react';
import { Box, useTheme, Button, Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
//assets
import AddIcon from '@mui/icons-material/Add';
//components
import Header from "../components/header/Header";
import ProductAlert from '../components/Products/ProductAlert';




const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Dashboard = () => {
    const theme = useTheme();

    return (
        <React.Fragment>
            {/* HEADER */}
            <Box m="20px">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title="DASHBOARD" subtitle="Proximos Productos" />
                    <Box>
                        <Button variant="contained">
                            <AddIcon />
                            Nuevo Producto
                        </Button>
                    </Box>
                </Box>
                {/* 
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item xs={4}>
                        <Item>
                            <ProductAlert clasificacion={"A"} />
                        </Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item>
                            <ProductAlert clasificacion={"B"} />
                        </Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item>
                            <ProductAlert clasificacion={"C"} />
                        </Item>
                    </Grid>
                </Grid>F
                */}
                
            </Box>
        </React.Fragment>
    )
}
export default Dashboard;