import React from 'react'
import { Box, Link, Button, Stack, Typography, Breadcrumbs, Grid } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
//assets
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
//Paginacion
import TablePagination from '@mui/material/TablePagination';
//components
import Header from "../../../components/header/Header";
import { ModelFormCreate } from '../../../sections/model/createModel/ModelFormCreate';
import ListProducts from '../../../sections/model/ListProducts';

import { useNavigate } from "react-router-dom";
import { OverviewProduct } from './OverviewProduct';
//services
import api from "../../../services/api";
import ChartModel from './ChartModel';

const style = {
    width: '100%',
    bgcolor: 'background.paper',
};

const CreateModel = () => {
    const navigate = useNavigate()
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [productsList, setproductsList] = React.useState([]);
    const [product, setproduct] = React.useState(null);
    const fetchData = async () => {
        await api.get('/products/').then(res => {
            setproductsList(res.data);
        }).catch(error => console.log(error))
    }

    React.useEffect(() => {
        fetchData();
    }, []);

    //Paginacion
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    //Paginacion
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const [showClearIcon, setShowClearIcon] = React.useState("none");

    const handleChange = event => {
        setShowClearIcon(event.target.value === "" ? "none" : "flex")
    }


    const handleClick = () => {
        // TODO: Clear the search input
        console.log("clicked the clear icon...")
    }

    const breadcrumbs = [
        <Link underline="none" key="1" color="inherit" disable >
            Estudio de productos
        </Link>,
        <Link underline="none" key="1" color="inherit" onClick={() => navigate("/studyProducts/create-model")} >
            Modelo
        </Link>,
        <Typography key="3" color="text.primary">
            Crear Modelo
        </Typography>,
    ];

    return (
        <React.Fragment>
            {/* Breadcrumbx */}
            <Box m="20px">
                <Stack spacing={2}>
                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                    >
                        {breadcrumbs}
                    </Breadcrumbs>
                </Stack>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={7} lg={5}>
                        <Grid container spacing={2}>
                            {product && (
                                <Grid item xs={12} >
                                    <OverviewProduct
                                        difference={12}
                                        positive
                                        sx={{ height: '100%' }}
                                        value={product}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Box sx={{ width: '100%' }}>
                                    <Typography sx={{ fontWeight: 300, fontSize: '1.25rem', lineHeight: 1.72, letterSpacing: '0.22px' }}>
                                        Productos
                                    </Typography>
                                    <FormControl sx={{ margin: 0 }}>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            onChange={handleChange}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment
                                                        position="end"
                                                        style={{ display: showClearIcon }}
                                                        onClick={handleClick}
                                                    >
                                                        <ClearIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </FormControl>
                                    <Stack spacing={2} sx={{ height: "100%" }}>
                                        <List sx={style} component="nav" aria-label="mailbox folders">
                                            {productsList
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map(item => (
                                                    <ListItem button key={item._id} onClick={() => setproduct(item)}>
                                                        <ListItemText primary={item.name} />
                                                    </ListItem>
                                                ))}
                                        </List>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 25, 100]}
                                            component="div"
                                            count={productsList.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </Stack>
                                </Box>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={5} lg={7}>
                        <ModelFormCreate product={product}/>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    )
}

export default CreateModel