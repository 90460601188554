import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import { FormControl, FormLabel, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
//assets
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
//swal
import Swal from 'sweetalert2';
//services
import UserContext from '../../services/userProvider';
import supplier from '../../services/supplier';

import { useNavigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ModalCreateSupplier = () => {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState("")
    const [identification, setIdentification] = React.useState("")
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [webPage, setWebPage] = useState("");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleSubmit = () => {
        setOpen(false);
    }

    const createSupplier = async () => {
        const data = JSON.parse(localStorage.getItem('user'))
        const response = supplier.createSupplier(name, identification, address, phone, webPage, email, data.id);
        console.log(response)
        navigate(0);
        handleClose()
    }

    return (
        <>
            <Button variant="contained" onClick={handleClickOpen}>
                <AddIcon />
                Nuevo Proveedor
            </Button>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Nuevo Proveedor
                        </Typography>
                        <Button autoFocus variant="outlined" color="outlined" onClick={createSupplier}>
                            Guardar
                        </Button>
                    </Toolbar>
                </AppBar>
                <Box
                    component="form"
                    sx={{
                        alignItems: 'center',
                        '& > :not(style)': { m: 1 },
                    }}
                    autoComplete="off"
                    onSubmit={handleSubmit}
                >
                    <form>
                        <Grid container spacing={7}>
                            <Grid item xs={12} sm={6} sx={{ marginTop: 4.8 }}>
                                <TextField fullWidth label='RUC/Cédula'
                                    value={identification}
                                    onChange={(e) => setIdentification(e.target.value)} />
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{ marginTop: 4.8 }}>
                                <TextField fullWidth label='Nombre del Proveedor'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth type='number' label='Teléfono' placeholder='(+593) 999 5604'
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label='Sitio Web'
                                    placeholder='https://example.com/'
                                    value={webPage}
                                    onChange={(e) => setWebPage(e.target.value)} />

                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label='Correo Electrónico'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)} 
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    multiline
                                    label='Dirección'
                                    minRows={2}
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)} 
                                />
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Dialog>
        </>
    );
}

export default ModalCreateSupplier
