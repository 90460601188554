import React from 'react'
import { Box, Link, Button, Stack, Typography, Breadcrumbs } from '@mui/material';
//assets
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
//components
import Header from "../../components/header/Header";
import SuppliersTable from '../../components/table/SuppliersTable';
import ModalCreateSupplier from '../../components/modal/ModalCreateSupplier';

const Supplier = () => {
    const breadcrumbs = [
        <Link underline="none" key="1" color="inherit" disable >
            Inventario
        </Link>,
        <Typography key="3" color="text.primary">
            Proveedores
        </Typography>,
    ];

    return (
        <React.Fragment>
            {/* Breadcrumbx */}
            <Box m="20px">
                <Stack spacing={2}>
                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                    >
                        {breadcrumbs}
                    </Breadcrumbs>
                </Stack>
                {/* HEADER */}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title="PROVEEDORES" subtitle="Administra tus Proveedores" />
                    <Box>
                        <ModalCreateSupplier />
                    </Box>
                </Box>
                <SuppliersTable />
            </Box>
        </React.Fragment>
    )
}

export default Supplier