import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./themeConfig";

import PortalNavbar from "./pages/navbar/PortalNavbar";
import PortalSidebar from "./pages/sidebar/PortalSidebar";
import UserContext from "./services/userProvider";

const App = () => {
  const [theme, colorMode] = useMode();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [currentUser, setCurrentUser] = useState(undefined);

  const checkUserToken = () => {
    const userToken = localStorage.getItem('user-token');
    if (!userToken || userToken === 'undefined') {
      setIsLoggedIn(false);
      setCurrentUser(JSON.parse(localStorage.getItem('user')));
    }
    setIsLoggedIn(true);
  }
  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);

  return (
    <UserContext.Provider value={[currentUser, setCurrentUser]}>
      <React.Fragment>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <div id="app" style={{ height: "100%", minHeight: "100vh", width: "100%", display: "flex", overflow: "none", borderStyle: "none", border: 0}}>
              {isLoggedIn && <PortalSidebar />}
              <CssBaseline />
              <main style={{ width: "100%"}}>
                {isLoggedIn && <PortalNavbar />}
                <Outlet />
              </main>
            </div>
          </ThemeProvider>
        </ColorModeContext.Provider>
      </React.Fragment >
    </UserContext.Provider>
  )
}

export default App

