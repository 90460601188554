import React from 'react'
import { Box, Link, Button, Stack, Typography, Breadcrumbs } from '@mui/material';

import { useNavigate } from "react-router-dom";

//assets
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
//components
import Header from "../../components/header/Header";
import UsersTable from '../../components/table/UsersTable';
import ModalCreateUser from '../../components/modal/ModalCreateUser';

const AdminUser = () => {
    const navigate = useNavigate()
   
    const breadcrumbs = [
        <Link underline="none" key="1" color="inherit" disable >
            Perfil
        </Link>,
        <Link underline="none" key="1" color="inherit" onClick={() => navigate("/profile")} >
            Usuario
        </Link>,
        <Typography key="3" color="text.primary" >
            Administrar Usuario
        </Typography>,
    ];

    return (
        <React.Fragment>
            {/* Breadcrumbx */}
            <Box m="20px">
                <Stack spacing={2}>
                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                    >
                        {breadcrumbs}
                    </Breadcrumbs>
                </Stack>
                {/* HEADER */}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title="Administrar Usuarios" subtitle="Administra tus usuarios" />
                    <Box>
                        <Button variant="contained" onClick={() => navigate("/profile/admin-role")}>
                            Administrar Perfiles
                        </Button>
                        {" "}
                        <ModalCreateUser />
                    </Box>
                </Box>
                <UsersTable />
            </Box>
        </React.Fragment>
    )
}
export default AdminUser