// ** React Imports
import { useState } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import InputLabel from '@mui/material/InputLabel'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'

// ** Icons Imports
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import ProfileImage from "../../assets/images/Default_Profile.png"
import userService from '../../services/userService'
import Swal from 'sweetalert2'

const TabSecurity = (props) => {
    const {user} = props
    // ** States
    const [values, setValues] = useState({
        newPassword: '',
        showNewPassword: false,
        confirmNewPassword: '',
        showConfirmNewPassword: false
    })

    // Handle Current Password
    const handleCurrentPasswordChange = prop => event => {
        setValues({ ...values, [prop]: event.target.value })
    }

    const handleClickShowCurrentPassword = () => {
        setValues({ ...values, showCurrentPassword: !values.showCurrentPassword })
    }

    const handleMouseDownCurrentPassword = event => {
        event.preventDefault()
    }

    // Handle New Password
    const handleNewPasswordChange = prop => event => {
        setValues({ ...values, [prop]: event.target.value })
    }

    const handleClickShowNewPassword = () => {
        setValues({ ...values, showNewPassword: !values.showNewPassword })
    }

    const handleMouseDownNewPassword = event => {
        event.preventDefault()
    }

    // Handle Confirm New Password
    const handleConfirmNewPasswordChange = prop => event => {
        setValues({ ...values, [prop]: event.target.value })
    }

    const handleClickShowConfirmNewPassword = () => {
        setValues({ ...values, showConfirmNewPassword: !values.showConfirmNewPassword })
    }

    const handleMouseDownConfirmNewPassword = event => {
        event.preventDefault()
    }
    const guardar = () => {
        if(values.newPassword === values.confirmNewPassword) {
            userService.changePassword(user._id, values.newPassword)
        }else{
            Swal.fire({
                icon: 'error',
                title: "Las contraseñas no coincieden",
                showConfirmButton: true
            })
        }
        
    }
    return (
        <form>
            <CardContent sx={{ paddingBottom: 0 }}>
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={5}>
                            <Grid item xs={12} sx={{ marginTop: 4 }}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor='account-settings-new-password'>Nueva Contraseña</InputLabel>
                                    <OutlinedInput
                                        label='New Password'
                                        value={values.newPassword}
                                        id='account-settings-new-password'
                                        onChange={handleNewPasswordChange('newPassword')}
                                        type={values.showNewPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    edge='end'
                                                    onClick={handleClickShowNewPassword}
                                                    aria-label='toggle password visibility'
                                                    onMouseDown={handleMouseDownNewPassword}
                                                >
                                                    {values.showNewPassword ? <RemoveRedEyeOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor='account-settings-confirm-new-password'>Confirmar Nueva Contaseña</InputLabel>
                                    <OutlinedInput
                                        label='Confirm New Password'
                                        value={values.confirmNewPassword}
                                        id='account-settings-confirm-new-password'
                                        type={values.showConfirmNewPassword ? 'text' : 'password'}
                                        onChange={handleConfirmNewPasswordChange('confirmNewPassword')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    edge='end'
                                                    aria-label='toggle password visibility'
                                                    onClick={handleClickShowConfirmNewPassword}
                                                    onMouseDown={handleMouseDownConfirmNewPassword}
                                                >
                                                    {values.showConfirmNewPassword ? <RemoveRedEyeOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        sm={6}
                        xs={12}
                        sx={{ display: 'flex', marginTop: [7.5, 2.5], alignItems: 'center', justifyContent: 'center' }}
                    >
                        <img width={250} alt='avatar' src={ProfileImage} />
                    </Grid>
                </Grid>
            </CardContent>

            <Divider sx={{ margin: 0 }} />

            <CardContent>
                {/*
                <Box sx={{ mt: 1.75, display: 'flex', alignItems: 'center' }}>
                    <KeyOutlinedIcon sx={{ marginRight: 3 }} />
                    <Typography variant='h6'>Autenticación de dos factores</Typography>
                </Box>

                <Box sx={{ mt: 5.75, display: 'flex', justifyContent: 'center' }}>
                    <Box
                        sx={{
                            maxWidth: 368,
                            display: 'flex',
                            textAlign: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <Avatar
                            variant='rounded'
                            sx={{ width: 48, height: 48, color: 'common.white', backgroundColor: 'primary.main' }}
                        >
                            <LockOpenOutlinedIcon sx={{ fontSize: '1.75rem' }} />
                        </Avatar>
                        <Typography sx={{ fontWeight: 600, marginTop: 3.5, marginBottom: 3.5 }}>
                            La autenticación de dos factores aún no está habilitada.
                        </Typography>
                        <Typography variant='body2'>
                            La autenticación de dos factores agrega una capa adicional de seguridad a su cuenta al requerir más que solo
                            una contraseña para iniciar sesión.
                        </Typography>
                    </Box>
                </Box>
*/}
                <Box sx={{ mt: 11 }}>
                    <Button variant='contained' sx={{ marginRight: 3.5 }} onClick={guardar}>
                        Guardar
                    </Button>
                    <Button
                        type='reset'
                        variant='outlined'
                        color='secondary'
                        onClick={() => setValues({ ...values, currentPassword: '', newPassword: '', confirmNewPassword: '' })}
                    >
                        Cancelar
                    </Button>
                </Box>
            </CardContent>
        </form>
    )
}

export default TabSecurity