import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Container, InputAdornment, TextField } from "@mui/material";

//assets
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/Menu';

//Paginacion
import TablePagination from '@mui/material/TablePagination';
//services
import api from "../../services/api";
import collections from '../../services/collections';
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import ModalEditCollection from '../modal/ModalEditCollection';
import store from '../../services/store';
import Swal from 'sweetalert2';
import ModalEditStockRoom from '../modal/ModalEditStockRoom';


const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

function Row(props) {
    const navigate = useNavigate();
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openOptions = Boolean(anchorEl);
    const handleClickOptions = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseOptions = () => {
        setAnchorEl(null);
    };
    const deleteWarehose = (id) => {
        Swal.fire({
            title: '¿Estás seguro de eliminar la bodega?',
            text: "¡No podrás revertirlo!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) { }
            store.deleteWarehouse(id)
            //   navigate(0);
            handleCloseOptions()
        })
    }

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell component="th" scope="row">{row.name}</TableCell>
                <TableCell >{row.ubication}</TableCell>
                <TableCell align="right">
                    <Button
                        id="demo-customized-button"
                        aria-controls={openOptions ? 'demo-customized-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openOptions ? 'true' : undefined}
                        variant="contained"
                        disableElevation
                        onClick={handleClickOptions}
                        endIcon={<KeyboardArrowDownIcon />}
                    >
                        <MenuIcon />
                    </Button>
                    <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={openOptions}
                        onClose={handleCloseOptions}
                    >
                        <ModalEditStockRoom warehouse={row} />
                        <MenuItem onClick={() => deleteWarehose(row._id)} disableRipple>
                            <DeleteIcon />
                            Eliminar
                        </MenuItem>
                    </StyledMenu>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}




const StockRoonTable = () => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [collectionList, setCollectionList] = React.useState([]);
    const [listSearch, setlistSearch] = React.useState([]);
    const [searchTerm, setSearchTerm] = React.useState("");
    //busqueda
    const handleChangeSearch = (event) => {
        setSearchTerm(event.target.value);
        if (!searchTerm) {
            setCollectionList(listSearch)
        } else {
            filtrar(event.target.value);
        }
    };
    //el filtro de la busqueda por el termino de busqueda
    const filtrar = (terminoBusqueda) => {
        var resultadosBusqueda = listSearch.filter((item) => {
            if (item.name?.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
                || item.sku?.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
            ) {
                return item;
            }
        });
        setCollectionList(resultadosBusqueda);
    }

    React.useEffect(() => {
        function fetchData() {
            store.getProductos().then(res => {
                let data = res.stores.sort((a, b) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });
                if (!searchTerm) {
                    setCollectionList(data)
                }
                setlistSearch(data)
            }).catch(error => console.log(error))
        }
        fetchData();
    }, [collectionList]);
    //Paginacion
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    //Paginacion
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        <>
            <TableContainer component={Paper}>
                <Container maxWidth="md" sx={{ mt: 1 }}>
                    <TextField
                        id="search"
                        type="search"
                        label="Buscar la bodega por el nombre"
                        value={searchTerm}
                        onChange={handleChangeSearch}
                        sx={{ width: 600 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Container>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nombre</TableCell>
                            <TableCell>Ubicación</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {collectionList
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => (
                                <Row key={row._id} row={row} />
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={collectionList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
}

export default StockRoonTable
