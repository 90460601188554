import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

//assets
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EyeIcon from '@mui/icons-material/RemoveRedEye';
//services
import api from "../../services/api"

export default function ListProducts(props) {
    const { model } = props;
    const [showClearIcon, setShowClearIcon] = React.useState("none");
    const [productsList, setproductsList] = React.useState([]);

    const fetchData = async () => {
        await api.get('/products/').then(res => {
            setproductsList(res.data.filter(function (item) {
                return item.model?.name == model.name;
            }));
        }).catch(error => console.log(error))
    }
    React.useEffect(() => {
        fetchData();
    }, []);


    const handleChange = event => {
        setShowClearIcon(event.target.value === "" ? "none" : "flex")
    }


    const handleClick = () => {
        // TODO: Clear the search input
        console.log("clicked the clear icon...")
    }

    return (
        <>
            <FormControl sx={{ margin: 0 }}>
                <TextField
                    size="small"
                    variant="outlined"
                    onChange={handleChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment
                                position="end"
                                style={{ display: showClearIcon }}
                                onClick={handleClick}
                            >
                                <ClearIcon />
                            </InputAdornment>
                        )
                    }}
                />
            </FormControl>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {productsList.map((value) => (
                    <ListItem
                        key={productsList._id}
                        disableGutters
                        secondaryAction={
                            <>
                                <IconButton aria-label="comment">
                                    <DeleteIcon color="error" />
                                </IconButton>
                                <IconButton aria-label="comment">
                                    <EyeIcon />
                                </IconButton>
                            </>
                        }
                    >
                        <ListItemText primary={value.name} />
                    </ListItem>
                ))}
            </List>
        </>
    );
}