import { useCallback, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    TextField,
    Chip,
    Unstable_Grid2 as Grid
} from '@mui/material';
//assets
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const states = [
    {
        value: 'alabama',
        label: 'Alabama'
    },
    {
        value: 'new-york',
        label: 'New York'
    },
    {
        value: 'san-francisco',
        label: 'San Francisco'
    },
    {
        value: 'los-angeles',
        label: 'Los Angeles'
    }
];

export const ModelDetails = (props) => {
    const { model } = props;
    const [values, setValues] = useState({
        firstName: 'Usuario',
        lastName: 'Demo',
        email: 'demo@scandere.net',
        phone: '',
        state: 'los-angeles',
        country: 'USA'
    });

    const handleChange = useCallback(
        (event) => {
            setValues((prevState) => ({
                ...prevState,
                [event.target.name]: event.target.value
            }));
        },
        []
    );

    const handleSubmit = useCallback(
        (event) => {
            event.preventDefault();
        },
        []
    );

    return (
        <form
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit}
        >
            <Card>
                <CardHeader
                    subheader="La información puede ser editada"
                    title={model.name}
                />
                <CardContent sx={{ pt: 0 }}>
                    <Box sx={{ m: -1.5 }}>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                xs={12}
                                md={12}
                            >
                                <TextField
                                    fullWidth
                                    label="Descripción:"
                                    name="description"
                                    onChange={handleChange}
                                    value={model.description}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <Divider>
                                    <Chip label="COSTO" />
                                </Divider>
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <TextField
                                    fullWidth
                                    label="Mantenimiento"
                                    name="firstName"
                                    type="number"
                                    onChange={handleChange}
                                    required
                                    value={model.maintenance.value}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <TextField
                                    fullWidth
                                    label="Por Ordenar o fijo"
                                    name="lastName"
                                    type="number"
                                    onChange={handleChange}
                                    required
                                    value={model.forAsking.value}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <Divider>
                                    <Chip label="TIEMPO" />
                                </Divider>
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <TextField
                                    fullWidth
                                    label="Anticipación"
                                    name="anticipation"
                                    type="number"
                                    onChange={handleChange}
                                    required
                                    value={model.leadTime}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <TextField
                                    fullWidth
                                    label="Anticipación máxima"
                                    name="maxAnticipation"
                                    onChange={handleChange}
                                    type="number"
                                    value={model.maxLeadTime}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={12}
                            >
                                <TextField
                                    fullWidth
                                    label="Dias laborables:"
                                    name="jobsdays"
                                    onChange={handleChange}
                                    type="number"
                                    value={model.businessDays}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
                <Divider />
                <CardActions sx={{ justifyContent: 'flex-end' }}>
                    <Button variant="contained" startIcon={<DeleteIcon />}>
                        Eliminar
                    </Button>
                    <Button variant="contained" startIcon={<SaveIcon />} >
                        Guardar
                    </Button>
                </CardActions>
                <CardActions sx={{ justifyContent: 'center' }}>
                    <Button variant="contained" startIcon={<PlayArrowIcon />} >
                        Aplicar Modelo
                    </Button>
                </CardActions>
                <Divider />
            </Card>
        </form>
    );
};