import axios from 'axios'; //npm i axios
import Swal from 'sweetalert2'


const instance = axios.create({
     baseURL: process.env.REACT_APP_REST
    //baseURL: 'http://95.216.213.200:8000/api'
});

instance.interceptors.request.use(
    (config) => {
        if (localStorage.getItem('user-token')) {
            config.headers["Authorization"] = 'Bearer ' + localStorage.getItem('user-token');  // para baerer
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    }, async function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        const originalConfig = error.config;
        if (originalConfig.url !== "/user/login") {
            // Access Token was expired
            if (error.response.status === 401) {
                originalConfig._retry = true;
                try {
                    const rs = await instance.post("/user/token", {
                        token: localStorage.getItem("user-token"),
                    });
                    localStorage.clear();
                    localStorage.setItem('user-token', rs.data.token);
                    localStorage.setItem("refreshToken", rs.data.refreshToken)
                    return instance(originalConfig);
                } catch (_error) {
                    localStorage.clear();
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: '¡Estuviste inactivo por mucho tiempo!',
                        allowOutsideClick: false,
                        showConfirmButton: true,
                    }).then(() => {
                        window.location.href = "/auth/login"
                    })
                    return Promise.reject(_error);
                }
            }

        }
        return Promise.reject(error);
    }

);

export default instance;