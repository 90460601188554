import PropTypes from 'prop-types';
import { Avatar, Card, CardContent, Stack, SvgIcon, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

export const OverviewProduct = (props) => {
    const { difference, positive = false, sx, value } = props;

    return (
        <Card sx={sx}>
            <CardContent>
                <Stack
                    alignItems="flex-start"
                    direction="row"
                    justifyContent="space-between"
                    spacing={3}
                >
                    <Stack spacing={1}>
                        <Typography
                            color="text.secondary"
                            variant="overline"
                        >
                            Detalles del Producto
                        </Typography>
                        <Typography variant="h4">
                            {value.name}
                        </Typography>
                    </Stack>
                    <Avatar
                        sx={{
                            backgroundColor: 'primary.main',
                            height: 56,
                            width: 56
                        }}
                    >
                        <SvgIcon>
                            <InfoIcon />
                        </SvgIcon>
                    </Avatar>
                </Stack>
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={2}
                    sx={{ mt: 2 }}
                >
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={0.5}
                    >
                        <Typography
                            color='primary.main'
                            variant="body2"
                        >
                            SKU:
                        </Typography>
                    </Stack>
                    <Typography
                        color="text.secondary"
                        variant="caption"
                    >
                        {value.sku}
                    </Typography>
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={0.5}
                    >
                        <Typography
                            color='primary.main'
                            variant="body2"
                        >
                            Costo:
                        </Typography>
                    </Stack>
                    <Typography
                        color="text.secondary"
                        variant="caption"
                    >
                        {value.cost}
                    </Typography>
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={0.5}
                    >
                        <Typography
                            color='primary.main'
                            variant="body2"
                        >
                            Precio:
                        </Typography>
                    </Stack>
                    <Typography
                        color="text.secondary"
                        variant="caption"
                    >
                        {value.price}
                    </Typography>
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={0.5}
                    >
                        <Typography
                            color='primary.main'
                            variant="body2"
                        >
                            Colección:
                        </Typography>
                    </Stack>
                    <Typography
                        color="text.secondary"
                        variant="caption"
                    >
                        {value.collectionName}
                    </Typography>
                </Stack>
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={2}
                    sx={{ mt: 2 }}
                >
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={0.5}
                    >
                        <Typography
                            color='primary.main'
                            variant="body2"
                        >
                            Demanda Anual Promedio:
                        </Typography>
                    </Stack>
                    <Typography
                        color="text.secondary"
                        variant="caption"
                    >
                        {value.averageAnnualDemand}
                    </Typography>
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={0.5}
                    >
                        <Typography
                            color='primary.main'
                            variant="body2"
                        >
                            Utilidad:
                        </Typography>
                    </Stack>
                    <Typography
                        color="text.secondary"
                        variant="caption"
                    >
                        {value.utility}
                    </Typography>
                    <Stack
                        alignItems="center"
                        direction="row"
                        spacing={0.5}
                    >
                        <Typography
                            color='primary.main'
                            variant="body2"
                        >
                            Clasificación:
                        </Typography>
                    </Stack>
                    <Typography
                        color="text.secondary"
                        variant="caption"
                    >
                        {value.clasification.type}
                    </Typography>
                    {value.model?.name && <>
                        <Stack
                            alignItems="center"
                            direction="row"
                            spacing={0.5}
                        >
                            <Typography
                                color='primary.main'
                                variant="body2"
                            >
                                Modelo Actual:
                            </Typography>
                        </Stack>
                        <Typography
                            color="text.secondary"
                            variant="caption"
                        >
                            {value.model?.name}

                        </Typography>
                        </>}
                </Stack>

            </CardContent>
        </Card>
    );
};

OverviewProduct.prototypes = {
    difference: PropTypes.number,
    positive: PropTypes.bool,
    sx: PropTypes.object,
    value: PropTypes.string.isRequired
};