import axios from "axios";
import api from "../services/api";
import Swal from 'sweetalert2'
import products from "./products";

const tryModel = async (idProducto, leadTime, maxLeadTime, businessDays, typeMaintenance, valueMaintenance, typeForAsking, valueForAasking) => {
    let salida = {};
    const data = {
        leadTime: leadTime,
        maxLeadTime: maxLeadTime,
        businessDays: businessDays,
        typeMaintenance: typeMaintenance,
        valueMaintenance: valueMaintenance,
        typeForAsking: typeForAsking,
        valueForAasking: valueForAasking,
    }
    if (idProducto) {
        await api.post('/models/testModel/' + idProducto, data).then(async (res) => {
            salida = res.data;
        }).catch((err) => {
            if (err.response.data?.message === "No existe la demana anual promedio") {
                Swal.fire({
                    title: 'El producto no tiene demanda',
                    input: 'text',
                    inputAttributes: {
                        autocapitalize: 'off'
                    },
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    showLoaderOnConfirm: true,
                    allowOutsideClick: () => !Swal.isLoading()
                }).then((result) => {
                    if (result.isConfirmed) {
                        products.colocarDemanda(idProducto, result.value).then(res => {
                            tryModel(idProducto, leadTime, maxLeadTime, businessDays, typeMaintenance, valueMaintenance, typeForAsking, valueForAasking)
                        }
                        )
                    }
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: err.response.data?.message || "Error desconocido",
                    showConfirmButton: true
                })
            }

        })
    } else {
        Swal.fire({
            icon: 'info',
            title: "Escoga un primero un producto",
            showConfirmButton: true
        })
    }

    return salida;
}
export default { tryModel }