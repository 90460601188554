import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
//assets
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddBoxIcon from '@mui/icons-material/AddBox';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/Menu';

//services
import api from "../../services/api";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import purchaserOrderService from '../../services/purchaserOrderService';
import InvoiceModal from '../../pages/inventory/invoice/InvoiceModal';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));


const columns = [
    { id: 'supplierName', label: 'Nombre del proveedor	', minWidth: 170 },
    { id: 'applicationDate', label: 'No. Orden de Compra', minWidth: 170 },
    { id: 'direction', label: 'Fecha de solicitud', minWidth: 100 },
    {
        id: 'totalPay',
        label: 'Total de Pago ($)',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    { id: 'option', label: '', minWidth: 100 },
];

export default function PurchaseOrderTable() {
    const navigate = useNavigate();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [info, setInfo] = React.useState({});
    const [isOpen, setIsOpen] = React.useState(false);
    const [purchaseOrderList, setPurchaseOrder] = React.useState([])

    const [anchorEl, setAnchorEl] = React.useState(null);

    const openOptions = Boolean(anchorEl);

    const handleClickOptions = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseOptions = () => {
        setAnchorEl(null);
    };


    React.useEffect(() => {
        async function fetchData() {
            await api.get('/purchaseOrder/').then(res => {
                setPurchaseOrder(res.data.purchaseOrder);
            }).catch(error => console.log(error))
        }
        fetchData();
    }, [purchaseOrderList]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const eliminarOrdendeCompra = (id) => {
        Swal.fire({
            title: '¿Estás seguro de eliminar la orden de compra?',
            text: "¡No podrás revertirlo!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                purchaserOrderService.eliminarOrden(id).then(res => {
                    if (res.status) {
                        Swal.fire({
                            icon: 'success',
                            title: res.message,
                            showConfirmButton: false,
                            timer: 2000
                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: "Error al eliminar al producto",
                            showConfirmButton: false,
                            timer: 2000
                        })
                    }
                })
            }
        })
    }
    const openModal = (row) => {
        setIsOpen(true)
        setInfo(row)
    };
    const closeModal = (event) => setIsOpen(false);
    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <InvoiceModal
                    showModal={isOpen}
                    closeModal={closeModal}
                    info={info}
                    productos={info.products}
                />
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {purchaseOrderList && purchaseOrderList
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                                        <TableCell >
                                            {row.supplier?.name}
                                        </TableCell>
                                        <TableCell >
                                            {row.orderNumber}
                                        </TableCell>
                                        <TableCell>
                                            {(new Date(row.applicationDate).getMonth() > 8
                                                ? new Date(row.applicationDate).getMonth() + 1
                                                : "0" + (new Date(row.applicationDate).getMonth() + 1)) +
                                                "/" +
                                                (new Date(row.applicationDate).getDate() > 9
                                                    ? new Date(row.applicationDate).getDate()
                                                    : "0" + new Date(row.applicationDate).getDate()) +
                                                "/" +
                                                new Date(row.applicationDate).getFullYear()}
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.totalToPay}
                                        </TableCell>
                                        <TableCell align="right">
                                            <Button
                                                id="demo-customized-button"
                                                aria-controls={openOptions ? 'demo-customized-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={openOptions ? 'true' : undefined}
                                                variant="contained"
                                                disableElevation
                                                onClick={handleClickOptions}
                                                endIcon={<KeyboardArrowDownIcon />}
                                            >
                                                <MenuIcon />
                                            </Button>
                                            <StyledMenu
                                                id="demo-customized-menu"
                                                MenuListProps={{
                                                    'aria-labelledby': 'demo-customized-button',
                                                }}
                                                anchorEl={anchorEl}
                                                open={openOptions}
                                                onClose={handleCloseOptions}
                                            >
                                                {/* <MenuItem onClick={handleCloseOptions} disableRipple>
                                                    <EditIcon />
                                                    Editar
                                                </MenuItem>
                                                <MenuItem onClick={handleCloseOptions} disableRipple>
                                                    <FileDownloadIcon />
                                                    Descargar
                                                </MenuItem>*/}
                                                <MenuItem onClick={() => openModal(row)} disableRipple>
                                                    <VisibilityIcon />
                                                    Visualizar
                                                </MenuItem>
                                                <MenuItem onClick={() => navigate("/scm/purchaseOrders/stockroom/" + row._id)} disableRipple>
                                                    <AddBoxIcon />
                                                    Ingreso
                                                </MenuItem>
                                                <Divider />
                                                <MenuItem onClick={() => eliminarOrdendeCompra(row._id)} disableRipple>
                                                    <DeleteIcon />
                                                    Eliminar
                                                </MenuItem>
                                            </StyledMenu>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={purchaseOrderList?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}