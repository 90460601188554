import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import { Alert, FormControl, FormLabel, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
//assets
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
//swal
import Swal from 'sweetalert2';
//services
import UserContext from '../../services/userProvider';
import api from "../../services/api"
import collections from '../../services/collections';

import { useNavigate } from "react-router-dom";
import store from '../../services/store';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ModalEditStockRoom = (props) => {
    const { warehouse } = props;
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState(warehouse.name || "")
    const [ubication, setUbication] = React.useState(warehouse.ubication || "")
    const [errorAlerta, setErrorAlerta] = React.useState("")

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleSubmit = () => {
        setOpen(false);
    };

    const updateStore = async () => {
        store.updateStore(warehouse._id, name, ubication).then(res => {
            console.log(res)
            if (res.status === false) {
                setErrorAlerta(res.message || "Error desconocido")
            } else {
               // navigate(0);
                handleClose()
            }

        });
    }
    return (
        <>
            <MenuItem onClick={handleClickOpen} disableRipple>
                <EditIcon />
                Editar
            </MenuItem>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Editar Bodega - {warehouse.name}
                        </Typography>
                        <Button autoFocus variant="outlined" color="outlined" onClick={updateStore}>
                            Guardar
                        </Button>
                    </Toolbar>
                </AppBar>
                <Box
                    component="form"
                    sx={{
                        alignItems: 'center',
                        '& > :not(style)': { m: 1 },
                    }}
                    autoComplete="off"
                    onSubmit={handleSubmit}
                >
                    <div>
                        <TextField fullWidth label="Nombre de la Bodega" id="name" margin="normal" value={name} required onChange={(e) => setName(e.target.value)} />
                        <TextField
                            margin="normal"
                            id="standard-multiline-static"
                            label="Ubicacón"
                            onChange={(e) => setUbication(e.target.value)}
                            fullWidth
                            multiline
                            rows={2}
                            value={ubication}
                        />
                    </div>
                </Box>
                {errorAlerta && (
                    <Alert severity={errorAlerta === "Bodega creada" ? "success" : "error"} >{errorAlerta}</Alert>
                )}

            </Dialog>
        </>
    );
}

export default ModalEditStockRoom
