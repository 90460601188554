import React, { useContext } from 'react'
import { Box, Link, Button, Stack, Typography, Breadcrumbs, IconButton } from '@mui/material';
import Fab from '@mui/material/Fab';
import CircularProgress from '@mui/material/CircularProgress';

//assets
import FileUploadIcon from '@mui/icons-material/FileUpload';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CheckIcon from '@mui/icons-material/Check';

//components
import Header from "../../components/header/Header";
import ProductosTable from '../../components/table/ProductosTable';
import ModalCreateProduct from '../../components/modal/ModalCreateProduct';
import products from '../../services/products';


const Products = () => {
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);

    const selectFile = async (event) => {
        setSuccess(false);
        setLoading(true);
        const data = JSON.parse(localStorage.getItem('user'))
        products.selectFile(event.target.files[0], data._id)
        // 👇️ reset file input
        event.target.value = null;
        setSuccess(true);
        setLoading(false);
    }

    const breadcrumbs = [
        <Link underline="none" key="1" color="inherit" disable >
            Inventario
        </Link>,
        <Typography key="3" color="text.primary">
            Productos
        </Typography>,
    ];

    return (
        <React.Fragment>
            {/* Breadcrumbx */}
            <Box m="20px">
                <Stack spacing={2}>
                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                    >
                        {breadcrumbs}
                    </Breadcrumbs>
                </Stack>
                {/* HEADER */}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title="PRODUCTOS" subtitle="Administra tus productos" />
                    <Box>
                        <IconButton color="primary" aria-label="upload file" component="label" >
                            <input hidden accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" type="file" onChange={selectFile} />
                            {success ? <CheckIcon /> : <FileUploadIcon />}
                        </IconButton>
                        {" "}
                        <ModalCreateProduct />
                    </Box>
                </Box>
                <ProductosTable />
            </Box>
        </React.Fragment>
    )
}

export default Products
