import React from 'react'
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar, menuClasses } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
//assets
import logo from "../../assets/images/logo.png";
import logo_100 from "../../assets/images/logo-100.png";
import DashboardIcon from '@mui/icons-material/Dashboard';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import InventoryIcon from '@mui/icons-material/Inventory';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CollectionsIcon from '@mui/icons-material/Collections';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AbcIcon from '@mui/icons-material/Abc';
import StorageIcon from '@mui/icons-material/Storage';
import WarehouseIcon from '@mui/icons-material/Warehouse';

const PortalSidebar = () => {
    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();
    const theme = useTheme();

    const styleItem = {
        ['.' + menuClasses.button]: {
            '&:hover': {
                backgroundColor: theme.palette.background.default,
                color: theme.palette.primary.main,
            },
        },
    }
    return (
        <Sidebar
            breakPoint="md"
            transitionDuration={800}
            defaultCollapsed
            onMouseEnter={() => collapsed && collapseSidebar()}
            onMouseLeave={() => !collapsed && collapseSidebar()}
            backgroundColor="primary.main"
            style={{ borderStyle: "none", border: 0 }}
        >
            <Menu>
                <div style={{ height: "64px", display: "flex", alignItems: "center", marginRight: "0px", background: "primary.main", justifyContent: "center" }}>
                    <img src={collapsed ? logo : logo_100} height="auto" width="100%" alt="Logo" />
                </div>
                {/* <MenuItem icon={<DashboardIcon />} component={<Link to="/" />} rootStyles={styleItem}> Dashboard </MenuItem> */}
                <SubMenu icon={<InventoryIcon />} label="SCM"
                    rootStyles={{
                        ['& > .' + menuClasses.button]: {
                            '&:hover': {
                                color: theme.palette.primary.main,
                            },
                        },
                        ['.' + menuClasses.subMenuContent]: {
                            backgroundColor: theme.palette.background.paper,
                        },
                    }}
                >
                    <MenuItem component={<Link to="scm/products" />} icon={<ListAltIcon />} rootStyles={styleItem}> Productos </MenuItem>
                    <MenuItem component={<Link to="scm/collections" />} icon={<CollectionsIcon />} rootStyles={styleItem}> Colecciones </MenuItem>
                    <MenuItem component={<Link to="scm/suppliers" />} icon={<LocalShippingIcon />} rootStyles={styleItem}> Proveedores </MenuItem>
                    <MenuItem component={<Link to="scm/stockroom" />} icon={<WarehouseIcon />} rootStyles={styleItem}> Bodega </MenuItem>
                    <MenuItem component={<Link to="scm/purchaseOrders" />} icon={<ReceiptIcon />} rootStyles={styleItem}> Ordenes de compra </MenuItem>
                    <MenuItem component={<Link to="scm/inventory" />} icon={<ReceiptIcon />} rootStyles={styleItem}> Inventario </MenuItem>
                </SubMenu>
              { /* <SubMenu icon={<CoPresentIcon />} label="Estudio de Productos"
                    rootStyles={{
                        ['& > .' + menuClasses.button]: {
                            '&:hover': {
                                color: theme.palette.primary.main,
                            },
                        },
                        ['.' + menuClasses.subMenuContent]: {
                            backgroundColor: theme.palette.background.paper,
                        },
                    }}
                >
                    <MenuItem component={<Link to="studyProducts/ABCClassification" />} icon={<AbcIcon />} rootStyles={styleItem}> Clasificación ABC </MenuItem>
                    <MenuItem component={<Link to="studyProducts/models" />} icon={<StorageIcon />} rootStyles={styleItem}> Modelos </MenuItem>
                </SubMenu>*/}
            </Menu>
        </Sidebar >
    )
}

export default PortalSidebar
