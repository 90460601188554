import React, { useState } from 'react'

import { Box, Link, Button, Stack, Typography, Breadcrumbs, Divider, MenuItem, FormControl, Select, InputLabel, FormGroup, TextField, FormLabel, OutlinedInput, FormHelperText, InputAdornment, TextareaAutosize, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import DeleteIcon from '@mui/icons-material/Delete';


import Autosuggest from 'react-autosuggest';

//boostrap


import api from "../../services/api"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';

import productsService from "../../services/products"
import Swal from 'sweetalert2';
import purchaserOrderService from '../../services/purchaserOrderService';
import { useNavigate } from 'react-router-dom';



const breadcrumbs = [
    <Link underline="none" key="1" color="inherit" disable >
        Inventario
    </Link>,
    <Typography key="3" color="text.primary">
        Ordenes de compra
    </Typography>,
];

const AddPurchaseOrder = () => {
    const navigate = useNavigate();
    const [currency, setCurrency] = React.useState('$');
    const [supplier, setSupplier] = React.useState(null);
    const [discountRate, setDiscountRate] = React.useState('0');
    const [discountAmmount, setDiscountAmmount] = React.useState('0.00');
    const [taxRate, setTaxRate] = React.useState('0');
    const [taxAmmount, setTaxAmmount] = React.useState('0.00');
    const [subTotal, setSubTotal] = React.useState('0.00');
    const [total, setTotal] = React.useState('0.00');
    const fechaActual = new Date().toISOString().split('T')[0]
    const [fechaInicio, setFechaInicio] = React.useState(dayjs(fechaActual));
    const [fechaFin, setFechaFin] = React.useState(dayjs(fechaActual));
    const [paymentConditions, setPaymentConditions] = useState("Ninguna");
    const [placeDelivery, setPlaceDelivery] = useState("Ninguna");
    const [warranty, setWarranty] = useState("1");
    const [shippingAddress, setShippingAddress] = useState("");
    const [shippingMail, setShippingMail] = useState("");
    const [notes, setNotes] = useState("");
    const [productos, setProductos] = useState([])

    const [validated, setValidated] = useState(false);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [stock, setStock] = useState(1);
    const [price, setPrice] = useState("0");
    const [sku, setSku] = useState("");

    const [suppliersList, setSuppliersList] = React.useState([])
    //Sugerencias de productos
    const [a_Productos, setA_Productos] = useState([]);
    const [a_Busqueda, setA_Busqueda] = useState("");

    //Modal Agregar
    const [show, setShow] = useState(false);
    //Handle del modal add
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    React.useEffect(() => {
        async function fetchData() {
            await api.get('/suppliers/').then(res => {
                setSuppliersList(res.data.suppliers);
            }).catch(error => console.log(error))
        }
        fetchData();
        calcularTotal(productos)
    }, [suppliersList, taxRate, discountRate]);

    const handleChangeCurrency = (event) => {
        setCurrency(event.target.value);
    };

    const handleChangeSupplier = (event) => {
        setSupplier(event.target.value);
    };

    const addProducto = () => {
        const response = productsService.createProduct('', name, '', sku,  0, price, 0, "BODEGA", description);
        
        let producto = {
            // _id: resultadosBusqueda._id,
            name: name,
            amount: stock,
            sku: sku,
            description: description,
            unitPrice: price,
            totalPrice: price * stock,
            toDilevery: stock
        }
        let arrayProductos = []
        arrayProductos.push(...productos)
        arrayProductos.push(producto)
        setProductos((anterior) => [...anterior, producto])
        calcularTotal(arrayProductos)
        setName("");
        setSku("");
        setStock("");
        setDescription("");
        setPrice("");
        Swal.fire({
            icon: 'success',
            title: "Agregado",
            showConfirmButton: false,
            timer: 2000
        })
        handleClose();

    }
    const calcularTotal = (arrayProductos) => {
        let t = 0;
        let st = 0;
        let ta = 0.0;
        let da = 0.0;
        if (arrayProductos.length > 0) {
            arrayProductos.forEach((p) => {
                st = parseFloat(parseFloat(p.totalPrice) + st)
            })
        }
        if (parseFloat(taxRate) > 0 || parseFloat(discountRate) > 0) {
            ta = parseFloat(st * (parseFloat(taxRate) / 100)).toFixed(2);
            da = parseFloat(st * (parseFloat(discountRate) / 100)).toFixed(2);

        }
        t = parseFloat(parseFloat(st) - parseFloat(da) + parseFloat(ta)).toFixed(2);
        setSubTotal(parseFloat(st).toFixed(2));
        setTaxAmmount(ta)
        setDiscountAmmount(da)
        setTotal(t)
    }

    //evento cuando cambie el valor del texto de busqueda
    const onChange = (e, { newValue }) => {
        setA_Busqueda(newValue)
    }
    const inputProps = {
        placeholder: "Buscar producto",
        value: a_Busqueda,
        onChange
    }

    //para obtener la lista de sugerencias
    const onSuggestionsFetchRequested = async ({ value }) => {
        await api.get('/products/').then((response) => {
            var resultadosBusqueda = response.data.filter((item) => {
                if (item.name?.toString().toLowerCase().includes(value.toLowerCase())
                    || item.sku?.toString().toLowerCase().includes(value.toLowerCase())
                ) {
                    return item;
                }
            });
            setA_Productos(resultadosBusqueda);
        }).catch((error) => {
            console.log("No se pudo obtener datos, mayor detalle: ", error)
        })
    }
    //funcion que nos permite borrar las sugerencias
    const onSuggestionsClearRequested = () => {
        setA_Productos([])
    }
    //devuelve el texto que se mostrara en la caja de texto del autocomplete cuando seleccionas una sugerencia (item)
    const getSuggestionValue = (sugerencia) => {

        return sugerencia.sku + " - " + sugerencia.name + " - $" + sugerencia.price
    }
    //como se debe mostrar las sugerencias - codigo htmlf
    const renderSuggestion = (sugerencia) => (
        <span>
            {sugerencia.sku + " - " + sugerencia.name + " - $" + sugerencia.price}
        </span>
    )
    const sugerenciaSeleccionada = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {

        Swal.fire({
            title: suggestion.name + " -  $" + suggestion.price,
            text: "Ingrese la cantidad",
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Volver',
            showLoaderOnConfirm: true,
            preConfirm: (inputValue) => {
                if (isNaN(parseFloat(inputValue))) {
                    setA_Busqueda("")
                    Swal.showValidationMessage(
                        "Debe ingresar un valor númerico"
                    )
                } else {

                    let producto = {
                        //   _id: suggestion._id,
                        name: suggestion.name,
                        amount: parseInt(inputValue),
                        description: "",
                        sku: suggestion.sku,
                        unitPrice: parseFloat(suggestion.price),
                        totalPrice: parseFloat(suggestion.price) * parseFloat(inputValue),
                        toDilevery: parseInt(inputValue)
                    }
                    let arrayProductos = []
                    arrayProductos.push(...productos)
                    arrayProductos.push(producto)

                    setProductos((anterior) => [...anterior, producto])
                    calcularTotal(arrayProductos)
                }
            },
            allowOutsideClick: () => !Swal.isLoading()

        }).then((result) => {
            if (result.isConfirmed) {
                setA_Busqueda("")
            } else {
                setA_Busqueda("")
            }
        })
    }

    const realizarPedido = () => {
        purchaserOrderService.realizarPedido(supplier, fechaInicio, fechaFin, shippingMail,
            paymentConditions, placeDelivery, warranty, shippingAddress, notes, productos,
            total).then(res => {
                if (res.status) {
                    navigate("/scm/purchaseOrders/")
                }
            })
    }
    const eliminarProducto = (index) => {
        productos.splice(index, 1);
        calcularTotal(productos)
    }
    const handleChangeTaxRate = (value) => {
        setTaxRate(value);
        calcularTotal(productos);
    }
    const handleChangeDiscontRate = (value) => {
        setDiscountRate(value);
        calcularTotal(productos);
    }
    return (
        <Box m="20px" >
            <Stack spacing={2}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Stack>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                    <Paper sx={{ width: '100%', overflow: 'hidden', p: 4 }} >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Fecha de Pedido"
                                        value={fechaInicio}
                                        onChange={(newValue) => setFechaInicio(newValue)}
                                        fullWidth
                                        format="DD-MM-YYYY"
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Fecha de Entrega"
                                        value={fechaFin}
                                        onChange={(newValue) => setFechaFin(newValue)}
                                        format="DD-MM-YYYY"
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <br />
                        <Divider />
                        <br />
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Correo de envío"
                                    fullWidth
                                    type="email"
                                    value={shippingMail} onChange={(e) => setShippingMail(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-autowidth-label">Proveedor</InputLabel>
                                    <Select
                                        labelId="Proveedor"
                                        onChange={handleChangeSupplier}
                                        fullWidth
                                        label="Proveedor"
                                    >
                                        {suppliersList.map((item, index) => (
                                            <MenuItem value={item._id} key={item._id}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Dirección de Envío"
                                    multiline
                                    rows={2}
                                    fullWidth
                                    value={shippingAddress} onChange={(e) => setShippingAddress(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Condiciones de Entrega"
                                    fullWidth
                                    value={placeDelivery} onChange={(e) => setPlaceDelivery(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Garantía (meses)"
                                    fullWidth
                                    type="number"
                                    value={warranty} onChange={(e) => setWarranty(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <Divider />
                        <br />
                        <FormControl fullWidth>
                            <Autosuggest
                                suggestions={a_Productos}
                                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={onSuggestionsClearRequested}
                                getSuggestionValue={getSuggestionValue}
                                renderSuggestion={renderSuggestion}
                                inputProps={inputProps}
                                onSuggestionSelected={sugerenciaSeleccionada}
                                highlightFirstSuggestion={true}
                            />
                        </FormControl>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Producto</TableCell>
                                        <TableCell>Descripción</TableCell>
                                        <TableCell >Cantidad</TableCell>
                                        <TableCell >Costo&nbsp;({currency})</TableCell>
                                        <TableCell align="right">Total</TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {productos.map((item, index) => (
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={index}
                                        >
                                            <TableCell >{item.name}</TableCell>
                                            <TableCell >{item.description}</TableCell>
                                            <TableCell >{item.amount}</TableCell>
                                            <TableCell >{item.unitPrice}</TableCell>
                                            <TableCell align="right">{(item.totalPrice).toFixed(2)}</TableCell>
                                            <TableCell ><DeleteIcon color='error' onClick={() => eliminarProducto(index)} /></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <br />
                        <Button variant="contained" onClick={handleShow}>Añadir Producto</Button>
                        <br />
                        <Divider />
                        <br />
                        <Box >
                            <div className="d-flex flex-row align-items-start justify-content-between">
                                <span className="fw-bold">Subtotal:
                                </span>
                                <span>{currency}
                                    {subTotal}</span>
                            </div>
                            <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                                <span className="fw-bold">Descuento:</span>
                                <span>
                                    <span className="small ">({discountRate || 0}%)</span>
                                    {currency}
                                    {discountAmmount || 0}</span>
                            </div>
                            <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                                <span className="fw-bold">IVA:
                                </span>
                                <span>
                                    <span className="small ">({taxRate || 0}%)</span>
                                    {currency}
                                    {taxAmmount || 0}</span>
                            </div>
                            <hr />
                            <div className="d-flex flex-row align-items-start justify-content-between" style={{
                                fontSize: '1.125rem'
                            }}>
                                <span className="fw-bold">Total:
                                </span>
                                <span className="fw-bold">{currency}
                                    {total || 0}</span>
                            </div>
                        </Box>
                        <br />
                        <Divider />
                        <br />
                        <TextField
                            id="outlined-multiline-static"
                            label="Notas"
                            multiline
                            rows={2}
                            fullWidth
                            value={notes} onChange={(e) => setNotes(e.target.value)}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Stack spacing={2}>
                        <Button variant="contained" onClick={realizarPedido} fullWidth>Continuar</Button>
                        <Divider />
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-autowidth-label">Moneda</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={currency}
                                onChange={handleChangeCurrency}
                                fullWidth
                                label="Moneda"
                            >
                                <MenuItem value="$">USD (United States Dollar)</MenuItem>
                                <MenuItem value="£">GBP (British Pound Sterling)</MenuItem>
                                <MenuItem value="¥">JPY (JapaneseYen)</MenuItem>
                                <MenuItem value="¥">CNY (Chinese Renminbi)</MenuItem>
                                <MenuItem value="₿">BTC (Bitcoin)</MenuItem>
                            </Select>
                        </FormControl>
                        <Divider />
                        <TextField
                            label="IVA"
                            id="outlined-start-adornment"
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                            value={taxRate}
                            onChange={event => setTaxRate(event.target.value)}
                        />
                        <TextField
                            label="DESCUENTO"
                            id="outlined-start-adornment"
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                            value={discountRate}
                            onChange={event => setDiscountRate(event.target.value)}
                        />
                    </Stack>
                </Grid>
            </Grid>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Añadir Nuevo Producto</Modal.Title>
                </Modal.Header>
                <Formik
                    validate={() => {
                        setValidated(false)
                        let errores = {};
                        // Validacion nombre
                        if (!name) {
                            errores.name = '*Por favor ingresa una nombre. Campo obligatorio*'
                        } else if (!/^[\wÀ-ÿ\s,"-.:-;+*~´°!"#$%&/()=?|¬¨^`<>]{1,100}$/.test(name)) {
                            errores.name = 'El nombre no es correcto, no puede sobre pasar los 100 caracteres'
                        }
                        if (!/^[\wÀ-ÿ\s,"-.:-;+*~´°!"#$%&/()=?|¬¨^`<>]*$/.test(sku)) {
                            errores.description = 'La sku es incorrecto'
                        }
                        if (!errores.name || !errores.sku) {
                            setValidated(true)
                        }
                        return errores;
                    }}
                >
                    {({
                        handleBlur,
                        touched,
                        errors,
                    }) => (
                        <Form >
                            <Modal.Body>
                                <h6>*Los nombre es obligatorio*</h6>
                                <Row className="mb-3">
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="8" >
                                        <Form.Label>Nombre</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="name"
                                            name="name"
                                            placeholder="Nombre"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.name && !errors.name}
                                            isInvalid={!!errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" >
                                        <Form.Label>Cantidad</Form.Label>
                                        <Form.Control
                                            type="number"
                                            id="name"
                                            name="0"
                                            value={stock}
                                            onChange={(e) => setStock(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.stock && !errors.stock}
                                            isInvalid={!!errors.stock}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.stock}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="6" >
                                        <Form.Label>SKU</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="name"
                                            name="name"
                                            placeholder="sku"
                                            value={sku}
                                            onChange={(e) => setSku(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.sku && !errors.sku}
                                            isInvalid={!!errors.sku}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" >
                                        <Form.Label>Costo</Form.Label>
                                        <Form.Control
                                            type="number"
                                            id="name"
                                            name="0.0"
                                            placeholder="0.0"
                                            value={price}
                                            onChange={(e) => setPrice(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.price && !errors.price}
                                            isInvalid={!!errors.price}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.price}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>Descripción</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            id="description"
                                            name="description"
                                            placeholder="Escribir una descripción del producto"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            onBlur={handleBlur}
                                            isValid={touched.description && !errors.description}
                                            isInvalid={!!errors.description}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            {errors.description}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancelar
                                </Button>
                                <Button color="primary"
                                    onClick={addProducto}
                                    disabled={!validated}
                                >
                                    Guardar
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </Box >
    )
}

export default AddPurchaseOrder