import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import { FormControl, FormLabel, Grid, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
//assets
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
//swal
import Swal from 'sweetalert2';
//services
import UserContext from '../../services/userProvider';
import productos from '../../services/products';
import api from "../../services/api"

import { useNavigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ModalCreateProduct = (props) => {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [image, setImage] = React.useState({ preview: '', data: '', valido: true })
    const [name, setName] = React.useState('')
    const [sku, setSKU] = React.useState('')
    const [cost, setcost] = React.useState(0)
    const [price, setPrice] = React.useState(0)
    const [stock, setStock] = React.useState(0)
    const [partNumber, setPartNumber] = React.useState('')
    const [collectionName, setCollectionName] = React.useState('BODEGA');

    const [currentUser, setCurrentUser] = React.useContext(UserContext);
    const [collectionList, setCollectionList] = React.useState([])

    const [observaciones, setObservaciones] = React.useState("")

    const fetchData = async () => {
        await api.get('/collections/').then(res => {
            setCollectionList(res.data.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                return 0;
            }));
        }).catch(error => console.log(error))
    }

    React.useEffect(() => {
        fetchData();
    }, []);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleSubmit = () => {
        setOpen(false);
    }
    const handleChangeSelect = (event) => {
        setCollectionName(event.target.value);
    };
    //Funcion que valida la extension del archivo y almmacena su estado
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        let valido = true
        switch (file.type) {
            case 'image/png':
                valido = true
                break;
            case 'image/jpg':
                valido = true
                break;
            case 'image/jpeg':
                valido = true
                break;
            case '':
                valido = true
                break;
            default:
                valido = false
                ///Mensaje de error
                Swal.fire({
                    icon: 'error',
                    title: "Formato de la imagen no válido. Solo .jpeg, .jpg y .png",
                    showConfirmButton: false,
                    timer: 2000
                })
        }
        if (valido) {
            const img = {
                preview: URL.createObjectURL(file),
                data: file,
                valido: valido
            }
            setImage(img)
        }
    }
    const crearProducto = async () => {
        const data = JSON.parse(localStorage.getItem('user'))
        productos.createProduct(image.data, name, partNumber, sku, cost, price, stock, collectionName, observaciones, data.id).then(res => {
            handleClose()
            navigate(0);
        })
    }

    return (
        <>
            <Button variant="contained" onClick={handleClickOpen}>
                <AddIcon />
                Nuevo Producto
            </Button>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Nuevo Producto
                        </Typography>
                        <Button variant="outlined" color="outlined" onClick={crearProducto}>
                            Guardar
                        </Button>
                    </Toolbar>
                </AppBar>
                <Box
                    component="form"
                    sx={{
                        alignItems: 'center',
                        '& > :not(style)': { m: 1 },
                    }}
                    autoComplete="off"
                >
                    <div>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    Imagen de Producto:
                                    {image.preview && <img src={image.preview} alt="imagen de perfil" width='100' height='100' />}
                                    <IconButton color="primary" aria-label="upload picture" component="label">
                                        <input hidden accept="image/png, image/jpeg, image/jpg" onChange={handleFileChange} type="file" />
                                        <PhotoCamera />
                                    </IconButton>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth label="Nombre del Producto" id="name" margin="normal" value={name}
                                    required onChange={(e) => setName(e.target.value)} />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField fullWidth label="Número de Parte" id="name" margin="normal" value={partNumber}
                                    onChange={(e) => setPartNumber(e.target.value)} />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <FormControl margin="normal" fullWidth >
                                    <InputLabel >SKU</InputLabel>
                                    <OutlinedInput
                                        label="sku"
                                        value={sku}
                                        onChange={(e) => setSKU(e.target.value)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    margin="normal"
                                    label="Costo"
                                    id="cost"
                                    type="number"
                                    value={cost}
                                    mt={2}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    onChange={(e) => setcost(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    margin="normal"
                                    label="Precio"
                                    id="price"
                                    type="number"
                                    value={price}
                                    mt={2}
                                    fullWidth
                                    onChange={(e) => setPrice(e.target.value)}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    margin="normal"
                                    id="stock"
                                    label="Stock"
                                    type="number"
                                    value={stock}
                                    onChange={(e) => setStock(e.target.value)}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <FormControl
                                    margin="normal"
                                    fullWidth>
                                    <InputLabel id="demo-simple-select-label">Colección</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={collectionName}
                                        label="Colección"
                                        onChange={handleChangeSelect}
                                        defaultValue={collectionName}
                                    >
                                        {collectionList.map(item => (
                                            <MenuItem value={item.name} key={item._id}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="normal"
                                    id="standard-multiline-static"
                                    value={observaciones}
                                    label="Observación"
                                    onChange={(e) => setObservaciones(e.target.value)}
                                    fullWidth
                                    multiline
                                    rows={2}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Box>
            </Dialog>
        </>
    );
}

export default ModalCreateProduct
