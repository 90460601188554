// Importaciones necesarias
import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { visuallyHidden } from '@mui/utils';

//Paginacion
import TablePagination from '@mui/material/TablePagination';
//assets
import DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
//services
import api from "../../services/api";
import products from '../../services/products';

import ModalEditProductInventory from '../modal/ModalEditProductInventory';

import { useNavigate } from "react-router-dom";
import { Container, InputAdornment, TableSortLabel, TextField } from '@mui/material';
import purchaserOrderService from '../../services/purchaserOrderService';
import Swal from 'sweetalert2';
import store from '../../services/store';

// Estilos personalizados para el menú
const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    // Estilos CSS para el menú y sus elementos
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

// Definición de las columnas de la tabla
const headCells = [
    {
        id: 'orderNumber',
        numeric: true,
        disablePadding: true,
        label: 'Número de Orden',
    },
    {
        id: 'serialNumber',
        numeric: false,
        disablePadding: false,
        label: 'Número de serie',
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Nombre',
    },
    /*  {
          id: 'partNumber',
          numeric: false,
          disablePadding: false,
          label: 'Numero de Parte',
      },*/
    {
        id: 'warehouse',
        numeric: false,
        disablePadding: false,
        label: 'Bodega',
    },
    {
        id: 'sku',
        numeric: false,
        disablePadding: false,
        label: 'SKU',
    },
    {
        id: 'state',
        numeric: false,
        disablePadding: false,
        label: 'Estado del producto',
    },
    {
        id: 'observations',
        numeric: false,
        disablePadding: false,
        label: 'Observación',
    },
    {
        numeric: false,
        disablePadding: false,
        label: '',
    }
];

// Componente para la cabecera de la tabla
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } =
        props;


    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell, index) => (
                    <TableCell
                        key={index}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

// Componente para renderizar cada fila de la tabla
function Row(props) {
    const navigate = useNavigate();
    const { row, warehouseData } = props;
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openOptions = Boolean(anchorEl);

    const handleClickOptions = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseOptions = () => {
        setAnchorEl(null);
    };

    const deleteProduct = id => {
        Swal.fire({
            title: '¿Estás seguro de eliminar el producto?',
            text: "¡No podrás revertirlo!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) { }
            purchaserOrderService.deleteStockroom(id)
            props.getProductos()
            handleCloseOptions()
        })
    }
    // Función para obtener el nombre del almacén según el _id
    const getWarehouseName = (warehouseId) => {
        // Lógica para obtener el nombre del almacén desde el backend
        const warehouse = warehouseData.find((w) => w._id === warehouseId);
        return warehouse ? warehouse.name : '';
    };
    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} >
                <TableCell component="th" scope="row" align="right">{row.orderNumber}</TableCell>
                <TableCell >{row.serialNumber}</TableCell>
                <TableCell >{row.name}</TableCell>
                <TableCell >{getWarehouseName(row.wareHouse)}</TableCell>
                <TableCell >{row.sku}</TableCell>
                <TableCell >{row.state}</TableCell>
                <TableCell >{row.observations}</TableCell>
                <TableCell align="right">
                    <Button
                        id="demo-customized-button"
                        aria-controls={openOptions ? 'demo-customized-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openOptions ? 'true' : undefined}
                        variant="contained"
                        disableElevation
                        onClick={handleClickOptions}
                        endIcon={<KeyboardArrowDownIcon />}
                    >
                        <MenuIcon />
                    </Button>
                    <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={openOptions}
                        onClose={handleCloseOptions}
                    >
                        <ModalEditProductInventory product={row} />
                        <MenuItem
                            onClick={() => deleteProduct(row._id)}
                            disableRipple>
                            <DeleteIcon />
                            Eliminar
                        </MenuItem>
                    </StyledMenu>
                </TableCell>
            </TableRow>
        </React.Fragment >
    );
}

// Componente principal de la tabla de inventario
const InventoryTable = (props) => {
    // ... Lógica y estado local para manejar la paginación, búsqueda y ordenación de la tabla
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [productsList, setproductsList] = React.useState([]);
    const [productsListSearch, setproductsListSearch] = React.useState([]);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [warehouseData, setwarehouseData] = React.useState([]);

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        const res = stableSort(productsList, getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
        )
        setproductsList(res)
    };
    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
    // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
    // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
    // with exampleArray.slice().sort(exampleComparator)
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }
    // ... Lógica para obtener los datos de inventario desde el backend
    const fetchData = async () => {
        try {
            const response = await api.get('/stockRoom/');
            const stockRoomData = response.data.stockRoom;
            const newProducts = [];
            stockRoomData.forEach(item => {
                item.products?.forEach(producto => {
                    const newProducto = {
                        _id: producto._id,
                        orderNumber: item.orderNumber || '',
                        serialNumber: producto.serialNumber || '',
                        name: producto.name || '',
                        //  numberPart: producto.numberPart || '',
                        wareHouse: producto.wareHouse || '',
                        sku: producto.sku || '',
                        state: producto.state || '',
                        observations: producto.observations || '',
                    };

                    newProducts.push(newProducto);
                });
            });
            console.log(newProducts)
            setproductsList(newProducts);
            setproductsListSearch(newProducts);
            store.getProductos().then(res => setwarehouseData(res.stores))
        } catch (error) {
            console.log(error);
        }
    };




    React.useEffect(() => {
        fetchData();
    }, []);


    //busqueda
    const handleChangeSearch = (event) => {
        setSearchTerm(event.target.value);
        if (!searchTerm) {
            setproductsList(productsListSearch)
        } else {
            filtrar(event.target.value);
        }
    };
    //el filtro de la busqueda por el termino de busqueda
    const filtrar = (terminoBusqueda) => {
        if (terminoBusqueda.length >= 3) {
            var resultadosBusqueda = productsListSearch.filter((item) => {
                if (item.name?.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
                    || item.serialNumber?.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
                ) {
                    return item;
                }
            });
            setproductsList(resultadosBusqueda);
        }
    }

    //Paginacion
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    //Paginacion
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    // ... Renderización de la tabla con sus componentes relacionados
    return (
        <Paper sx={{ width: '100%', mb: 2, m: 1 }}>
            <Container maxWidth="md" sx={{ mb: 2, m: 1, mt: 1 }}>
                <TextField
                    id="search"
                    type="search"
                    label="Buscar un item del inventario por su número de serie o nombre"
                    value={searchTerm}
                    onChange={handleChangeSearch}
                    sx={{ width: '60%' }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Container>
            <TableContainer >
                <Table aria-label="collapsible table">
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {productsList && productsList
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => (
                                <Row key={index} row={row} warehouseData={warehouseData} getProductos={fetchData} />
                            ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={productsList?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </Paper>
    );
}

export default InventoryTable
