import { useCallback, useState, useEffect } from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    TextField,
    Chip,
    Unstable_Grid2 as Grid
} from '@mui/material';
//assets
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ChartModel from './ChartModel';
import model from '../../../services/model';


export const ModelFormCreate = (props) => {
    const { product } = props;
    console.log(product)
    const [quantityOrder, setQuantityOrder] = useState(0)
    const [reorderPoint, setReorderPoint] = useState(0)
    const [stockSecurity, setStockSecurity] = useState(0)

    const [values, setValues] = useState({
        leadTime: 0,
        maxLeadTime: 0,
        businessDays: 240,
        typeMaintenance: 'porcentaje',
        typeForAsking: 'porcentaje',
        valueMaintenance: 0,
        valueForAasking: 0,
    });

    useEffect(() => {
        tryModel()
    }, [product])

    const handleChange = useCallback(
        async (e) => {
            setValues((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value
            }));
            tryModel()
        },
        []
    );

    const tryModel = () => {
        model.tryModel(product?._id || null, values.leadTime, values.maxLeadTime, values.businessDays, values.typeMaintenance, values.valueMaintenance, values.typeForAsking, values.valueForAasking)
            .then(res => {
                console.log(res)
                setQuantityOrder(res.quantityOrder || 0)
                setReorderPoint(res.reorderPoint || 0)
                setStockSecurity(res.stockSecurity || 0)
            });
    }

    const handleSubmit = useCallback(
        (event) => {
            event.preventDefault();
        },
        []
    );

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <form
                    autoComplete="off"
                    noValidate
                    onSubmit={handleSubmit}
                >
                    <Card>
                        <CardHeader
                            subheader="Crea tu propio modelo"
                            title="Nuevo Modelo"
                        />
                        <CardContent sx={{ pt: 0 }}>
                            <Box sx={{ m: -1.5 }}>
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid xs={12}>
                                        <Divider>
                                            <Chip label="COSTO" />
                                        </Divider>
                                    </Grid>
                                    <Grid
                                        xs={12}
                                        md={6}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Mantenimiento"
                                            name="valueMaintenance"
                                            type="number"
                                            onChange={(e) => handleChange(e)}
                                            required
                                            defaultValue={values.valueMaintenance}
                                        />
                                    </Grid>
                                    <Grid
                                        xs={12}
                                        md={6}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Por Ordenar o fijo"
                                            name="valueForAasking"
                                            type="number"
                                            onChange={(e) => handleChange(e)}
                                            required
                                            value={values.valueForAasking}
                                        />
                                    </Grid>
                                    <Grid xs={12}>
                                        <Divider>
                                            <Chip label="TIEMPO" />
                                        </Divider>
                                    </Grid>
                                    <Grid
                                        xs={12}
                                        md={6}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Anticipación"
                                            name="leadTime"
                                            type="number"
                                            onChange={(e) => handleChange(e)}
                                            required
                                            value={values.leadTime}
                                        />
                                    </Grid>
                                    <Grid
                                        xs={12}
                                        md={6}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Anticipación máxima"
                                            name="maxLeadTime"
                                            onChange={(e) => handleChange(e)}
                                            type="number"
                                            value={values.maxLeadTime}
                                        />
                                    </Grid>
                                    <Grid
                                        xs={12}
                                        md={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Dias laborables:"
                                            name="businessDays"
                                            onChange={handleChange}
                                            type="number"
                                            value={values.businessDays}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                        <Divider />
                        <CardActions sx={{ justifyContent: 'flex-end' }}>
                            <Button variant="contained" startIcon={<SaveIcon />} >
                                Crear
                            </Button>
                        </CardActions>
                    </Card>
                </form>
            </Grid>
            <Grid item xs={12}>
                <ChartModel quantityOrder={quantityOrder} reorderPoint={reorderPoint} stockSecurity={stockSecurity} />
            </Grid>
        </Grid>
    );
};