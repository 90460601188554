import React from 'react'
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography
} from '@mui/material';
import { useNavigate } from "react-router-dom";



const AccountProfile = () => {
    const navigate = useNavigate()
    const data = JSON.parse(localStorage.getItem('user'))
    return (
        <Card>
            <CardContent>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Avatar
                        src={data.image || '/assets/avatars/avatar-anika-visser.png'}
                        sx={{
                            height: 80,
                            mb: 2,
                            width: 80
                        }}
                    />
                    <Typography
                        gutterBottom
                        variant="h5"
                    >
                        {data.name}
                    </Typography>
                    <Typography
                        color="text.secondary"
                        variant="body2"
                    >
                        {data.role}
                    </Typography>
                    <Typography
                        color="text.secondary"
                        variant="body2"
                    >
                        {data.email}
                    </Typography>
                </Box>
            </CardContent>
            <Divider />
            <CardActions>
                <Button
                    fullWidth
                    variant="text"
                >
                    Actualizar Foto
                </Button>
            </CardActions>
            <Divider />
            <CardActions>
                <Button
                    fullWidth
                    variant="contained"
                    onClick={() => navigate("/profile/adminUsers")}
                >
                    Administrar Usuarios
                </Button>
            </CardActions>
        </Card>
    )
}
export default AccountProfile