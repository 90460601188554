// ** MUI Imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Avatar from '@mui/material/Avatar'
import CardHeader from '@mui/material/CardHeader'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'

// ** Icons Imports



const renderStats = (salesData) => {
    return salesData.map((item, index) => (
        <Grid item xs={12} sm={4} key={index}>
            <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                    variant='rounded'
                    sx={{
                        mr: 3,
                        width: 44,
                        height: 44,
                        boxShadow: 3,
                        color: 'common.white',
                        backgroundColor: `${item.color}.main`
                    }}
                >
                    {item.icon}
                </Avatar>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant='caption'>{item.title}</Typography>
                    <Typography variant='h6'>{item.stats} %</Typography>
                </Box>
            </Box>
        </Grid>
    ))
}

const StatisticsCard = (props) => {
    const {a, b, c } = props;
    const salesData = [
        {
            stats: a * 100,
            title: 'CLASIFICACIÓN A',
            color: 'success',
            icon: "A"
        },
        {
            stats: b* 100,
            title: 'CLASIFICACIÓN B',
            color: 'warning',
            icon: "B"
        },
        {
            stats: c* 100,
            color: 'error',
            title: 'CLASIFICACIÓN C',
            icon: "C"
        }
    ]
    return (
        <Card>
            <CardHeader
                title='CLASIFICACIÓN'
                subheader={
                    <Typography variant='body2'>
                        Porcentaje:
                    </Typography>
                }
                titleTypographyProps={{
                    sx: {
                        mb: 2.5,
                        lineHeight: '2rem !important',
                        letterSpacing: '0.15px !important'
                    }
                }}
            />
            <CardContent sx={{ pt: theme => `${theme.spacing(3)} !important` }}>
                <Grid container spacing={[5, 0]}>
                    {renderStats(salesData)}
                </Grid>
            </CardContent>
        </Card>
    )
}

export default StatisticsCard