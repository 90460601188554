import React from 'react'
import { Box, Link, Button, Stack, Typography, Breadcrumbs, Grid } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
//assets
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

//components
import Header from "../../../components/header/Header";
import { ModelDetails } from '../../../sections/model/Model-details';
import ListProducts from '../../../sections/model/ListProducts';

import { useNavigate } from "react-router-dom";

//services
import api from "../../../services/api";

const style = {
  width: '100%',
  bgcolor: 'background.paper',
};

const Models = () => {
  const navigate = useNavigate()
  const [modelsList, setModelsList] = React.useState([]);
  const [showPanel, setShowPanel] = React.useState(false)
  const [model, setModel] = React.useState({});

  const fetchData = async () => {
    await api.get('/models/').then(res => {
      console.log(res.data)
      setModelsList(res.data);
      modelsList.sort((x, y) => x.name - y.namee);
      console.log(modelsList)
    }).catch(error => console.log(error))
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  const breadcrumbs = [
    <Link underline="none" key="1" color="inherit" disable >
      Estudio de productos
    </Link>,
    <Typography key="3" color="text.primary">
      Modelo
    </Typography>,
  ];

  return (
    <React.Fragment>
      {/* Breadcrumbx */}
      <Box m="20px">
        <Stack spacing={2}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Modelo" subtitle="Crea y Admnistra tus modelos" />
          <Box>
            <Button variant="contained" onClick={() => navigate("/studyProducts/create-model")}>
              <AddIcon />
              Nuevo Modelo
            </Button>
          </Box>
        </Box>
        <Grid container spacing={6}>
          <Grid item xs={12} md={showPanel ? 7 : 12}  lg={showPanel ? 5 : 12} >
            <Box sx={{ width: '100%' }}>
              <Typography sx={{ fontWeight: 300, fontSize: '1.25rem', lineHeight: 1.72, letterSpacing: '0.22px' }}>
                Modelos
              </Typography>
              <Stack spacing={2}>
                <List sx={style} component="nav" aria-label="mailbox folders">
                  {modelsList.map(item => (
                    <ListItem button key={item._id} onClick={() => {setShowPanel(true); setModel(item); }}>
                      <ListItemText primary={item.name} />
                    </ListItem>
                  ))}
                </List>
              </Stack>
            </Box>
          </Grid>
          {showPanel && (
            <Grid item xs={12} md={5} lg={7}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <ModelDetails model={model}/>
                </Grid>
                <Grid item xs={12}>
                  <ListProducts model={model}/>
                </Grid>
              </Grid>
            </Grid>
          )}

        </Grid>
      </Box>
    </React.Fragment>
  )
}

export default Models