import api from "./api";
import Swal from "sweetalert2";




const generateClasification = async (userID) => {
    Swal.fire({
        title: '¿Desear reiniciar por completo la clasificación?',
        text: "Si hay productos con categorias que no deseas cambiar, presiona 'No'.",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Si',
        denyButtonText: 'No',
        cancelButtonText: "Cancelar",
        customClass: {
            actions: 'my-actions',
            cancelButton: 'order-1 right-gap',
            confirmButton: 'order-2',
            denyButton: 'order-3',
        }
    }).then(async (result) => {
        if (result.isConfirmed) {
            const clasifiacion = {
                idCreator: userID,
                reiniciar: true
            }
            Swal.fire({
                position: 'center',
                title: 'Espera un momento...',
                showConfirmButton: false,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            })
            const resp = await api.post('/clasificacion/createClasificacion', clasifiacion)
            if (resp.data.status) {
                Swal.close();
                Swal.fire({
                    icon: 'success',
                    title: resp.data.message,
                    showConfirmButton: false,
                    timer: 2000
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: resp.data.message,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        } else if (result.isDenied) {
            
        }
    })
}
export default { generateClasification}