import React, {useState} from 'react'
import Button  from '@mui/material/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Formik } from 'formik';

const CreateClasification = () => {
    const [validated, setValidated] = useState(true);
    const [valueA, setValueA] = useState(20)
    const [valueB, setValueB] = useState(20)
    const [valueC, setValueC] = useState(60)
    const [tipoClasificacion, setTipoClasificacion] = useState("Costo Unitario")
    const [frecuenciaActualizacion, setFrecuenciaActualizacion] = useState("diariamente")
    //Modal Agregar
    const [show, setShow] = useState(false);

    //Handle del modal add
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const settingClasification = () => {

    }
    return (
        <>
            <Button variant="contained" onClick={handleShow} >
                Configurar Clasificación
            </Button>
            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Clasificación de productos</Modal.Title>
                </Modal.Header>
                <Formik
                    validate={() => {
                        setValidated(false)
                        let errores = {};
                        // Validacion nombre
                        let suma = valueA + valueB + valueC
                        if ((valueA + valueB + valueC) !== 100) {
                            errores.valor = '*Error:  La suma de los valores no es 100%. El valor total es ' + suma + '%'
                        }
                        if (suma === 100) {
                            setValidated(true)
                        }
                        return errores;
                    }}
                >
                    {({
                        handleBlur,
                        touched,
                        errors,
                    }) => (
                        <Form >
                            <Modal.Body>
                                <Row className="mb-3">
                                    <Form.Group className="position-relative mb-3">
                                        <Form.Label>Escoja el tipo de clasificación:</Form.Label>
                                        <Form.Select
                                            as="select"
                                            id="tipoClasificacion"
                                            name="tipoClasificacion"
                                            value={tipoClasificacion}
                                            onChange={(e) => setTipoClasificacion(e.target.value)}
                                        >
                                            <option value="Costo Unitario">Clasificación ABC por coste unitario</option>
                                            <option value="Valor total en Inventario">Clasificación ABC por valor total en inventario</option>
                                            <option value="Utilidad">Clasificación ABC por utilización y valor</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group className="position-relative mb-3">
                                        <Form.Label>Porcentaje de Clasificación ABC</Form.Label>
                                        <br />
                                        <Form.Text>A = {valueA}%</Form.Text>
                                        <Form.Range
                                            value={valueA}
                                            min="5"
                                            max="20"
                                            onChange={(e) => { setValueA(Number(e.target.value)) }}
                                            onBlur={handleBlur}
                                            step="5"
                                            isValid={touched.valor && !errors.valor}
                                            isInvalid={!!errors.valor}
                                        />
                                        <Form.Text>B = {valueB}%</Form.Text>
                                        <Form.Range
                                            value={valueB}
                                            onChange={(e) => setValueB(Number(e.target.value))}
                                            min="5"
                                            max="30"
                                            step="5"
                                            onBlur={handleBlur}
                                            isValid={touched.valor && !errors.valor}
                                            isInvalid={!!errors.valor}
                                        />
                                        <Form.Text>c = {valueC}%</Form.Text>
                                        <Form.Range
                                            value={valueC}
                                            onChange={(e) => setValueC(Number(e.target.value))}
                                            onBlur={handleBlur}
                                            min="50"
                                            max="90"
                                            step="5"
                                            isValid={touched.valor && !errors.valor}
                                            isInvalid={!!errors.valor}
                                        />
                                        <p style={{ color: "red" }} >
                                            {errors.valor}
                                        </p>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group md="4">
                                        <Form.Label>Frecuencia de clasificacion:</Form.Label>
                                        <Form.Select
                                            as="select"
                                            id="tipoClasificacion"
                                            name="tipoClasificacion"
                                            value={frecuenciaActualizacion}
                                            onChange={(e) => setFrecuenciaActualizacion(e.target.value)}
                                        >
                                            <option value="diariamente">Diariamente</option>
                                            <option value="semanalmente">Semanalmente</option>
                                            <option value="mensualmente">Mensualmente</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary"
                                    onClick={handleClose}
                                >
                                    Cancelar
                                </Button>
                                <Button variant="primary"
                                    onClick={settingClasification} disabled={!validated}
                                >
                                    Guardar
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </>
    )
}

export default CreateClasification
