import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";



const theme = createTheme({
    palette: {
        primary: {
            main: '#df5352',
        },
        secondary: {
            main: '#ff5733',
        },
        error: {
            main: '#ff0000',
        },
        info: {
            main: '#5065c0',
        },
    },
});
// context for color mode
export const ColorModeContext = createContext({
    toggleColorMode: () => { },
});
// mui theme settings
export const themeSettings = (mode) => {
    return {
        palette: {
            mode: mode,
            ...(mode === "dark"
                ? {
                    // palette values for dark mode
                    primary: {
                        main: '#5065c0',
                    },
                    secondary: {
                        main: '#082440',
                    },
                    info: {
                        main: '#df5352',
                    },
                    outlined: {
                        main: '#ffffff',
                    },
                    background: {
                        default: '#303030',
                        paper: '#424242',
                    },
                }
                : {
                    // palette values for light mode
                    primary: {
                        main: '#df5352',
                        light: "#E57574",
                        dark: '#9C3A39',
                        contrastText: '#fff',
                    },
                    secondary: {
                        main: '#ff5733',
                        light: "#FF785B",
                        dark: '#B23C23',
                        contrastText: '#fff',
                    },
                    error: {
                        main: '#ff0000',
                        light: "#FF3333",
                        dark: '#B20000',
                        contrastText: '#fff',
                    },
                    info: {
                        main: '#5065c0',
                        light: "#7383CC",
                        dark: '#384686',
                        contrastText: '#fff',
                    },
                    outlined: {
                        main: '#ffffff',
                    },
                    background: {
                        default: "#fbfbfb",
                    },
                }),
        },
        typography: {
            fontFamily: ["Roboto", "Source Sans Pro", "sans-serif"].join(","),
            fontSize: 12,
            h1: {
                fontFamily: ["Roboto", "Source Sans Pro", "sans-serif"].join(","),
                fontSize: 40,
            },
            h2: {
                fontFamily: ["Roboto", "Source Sans Pro", "sans-serif"].join(","),
                fontSize: 32,
            },
            h3: {
                fontFamily: ["Roboto", "Source Sans Pro", "sans-serif"].join(","),
                fontSize: 24,
            },
            h4: {
                fontFamily: ["Roboto", "Source Sans Pro", "sans-serif"].join(","),
                fontSize: 20,
            },
            h5: {
                fontFamily: ["Roboto", "Source Sans Pro", "sans-serif"].join(","),
                fontSize: 16,
            },
            h6: {
                fontFamily: ["Roboto", "Source Sans Pro", "sans-serif"].join(","),
                fontSize: 14,
            },
        },
    };
};
export const useMode = () => {
    const [mode, setMode] = useState("light");

    const colorMode = useMemo(
        () => ({
            toggleColorMode: () =>
                setMode((prev) => (prev === "light" ? "dark" : "light")),
        }),
        []
    );

    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
    return [theme, colorMode];
};

export default theme;