import React from 'react'
import {Container, Grid, Box, Typography, TextField, FormControlLabel, Checkbox, Button, Link } from '@mui/material';

const ForgetPassword = () => {
    return (
        <Container component="main" maxWidth="xs" >
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box
                    component="img"
                    sx={{
                        height: 33,
                        width: 150,
                        maxHeight: { xs: 233, md: 167 },
                        maxWidth: { xs: 350, md: 250 },
                    }}
                    alt="Logo"
                    src="https://scandere.net/static/media/logo.03a4efd1064d784486d2.png"
                />
                <br />
                <Typography component="h1" variant="h5">
                ¿Perdiste tu contraseña? Por favor, introduzca su dirección de correo electrónico. Recibirá un enlace para crear una nueva contraseña por correo electrónico.
                </Typography>
                <Box component="form"
                //onSubmit={handleSubmit} 
                //noValidate sx={{ mt: 1 }}
                >
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Correo electrónico"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Enviar
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="/" variant="body2">
                                volver
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    )
}

export default ForgetPassword
