// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider';

// ** Icons Imports
import ShowChartIcon from '@mui/icons-material/ShowChart';

const CardStatsVertical = props => {
    // ** Props
    const { title, subtitle, color, icon, stats, trend, trendNumber } = props

    return (
        <Card>
            <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontWeight: 600, fontSize: '0.875rem' }}>{title}</Typography>
                    <Avatar sx={{ boxShadow: 3, marginRight: 4, color: 'common.white', backgroundColor: `${color}.main` }}>
                        {icon}
                    </Avatar>
                </Box>
                <Divider />
                <Box sx={{ marginTop: 1.5, display: 'flex', flexWrap: 'wrap', marginBottom: 1.5, alignItems: 'flex-start' }}>
                    <Typography variant='h6' sx={{ mr: 2 }}>
                        {stats}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    )
}

export default CardStatsVertical

CardStatsVertical.defaultProps = {
    color: 'primary',
    trend: 'positive'
}