import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import { FormControl, FormLabel, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
//assets
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
//swal
import Swal from 'sweetalert2';
//services
import UserContext from '../../services/userProvider';
import api from "../../services/api"
import collections from '../../services/collections';

import { useNavigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ModalCreateCollection = () => {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [image, setImage] = React.useState({ preview: '', data: '', valido: true })
    const [name, setName] = React.useState("")
    const [collectionName, setCollectionName] = React.useState('BODEGA');
    const [description, setDescription] = React.useState("")
    
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleSubmit = () => {
        setOpen(false);
    };
    //Funcion que valida la extension del archivo y almmacena su estado
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        let valido = true
        switch (file.type) {
            case 'image/png':
                valido = true
                break;
            case 'image/jpg':
                valido = true
                break;
            case 'image/jpeg':
                valido = true
                break;
            case '':
                valido = true
                break;
            default:
                valido = false
                ///Mensaje de error
                Swal.fire({
                    icon: 'error',
                    title: "Formato de la imagen no válido. Solo .jpeg, .jpg y .png",
                    showConfirmButton: false,
                    timer: 2000
                })
        }
        if (valido) {
            const img = {
                preview: URL.createObjectURL(file),
                data: file,
                valido: valido
            }
            setImage(img)
        }
    }
    const crearCollection = async () => {
        const data = JSON.parse(localStorage.getItem('user'))
        const response = collections.createCollection(image.data, name, description, data.id);
        console.log(response)
        navigate(0);
        handleClose()
    }
    return (
        <>
            <Button variant="contained" onClick={handleClickOpen}>
                <AddIcon />
                Nueva Colección
            </Button>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Nueva Colección
                        </Typography>
                        <Button autoFocus variant="outlined" color="outlined" onClick={crearCollection}>
                            Guardar
                        </Button>
                    </Toolbar>
                </AppBar>
                <Box
                    component="form"
                    sx={{
                        alignItems: 'center',
                        '& > :not(style)': { m: 1 },
                    }}
                    autoComplete="off"
                    onSubmit={handleSubmit}
                >
                    <div>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            Imagen de la Colección:
                            {image.preview && <img src={image.preview} alt="imagen de coleccion" width='100' height='100' />}
                            <IconButton color="primary" aria-label="upload picture" component="label">
                                <input hidden accept="image/png, image/jpeg, image/jpg" onChange={handleFileChange} type="file" />
                                <PhotoCamera />
                            </IconButton>
                        </Stack>
                        <TextField fullWidth label="Nombre de la Colección" id="name" margin="normal" required onChange={(e) => setName(e.target.value)} />
                        <TextField
                            margin="normal"
                            id="standard-multiline-static"
                            label="Descripción"
                            onChange={(e) => setDescription(e.target.value)}
                            fullWidth
                            multiline
                            rows={2}
                        />
                    </div>
                </Box>
            </Dialog>
        </>
    );
}

export default ModalCreateCollection
