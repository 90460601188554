import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import { Alert, FormControl, FormLabel, Grid, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
//assets
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
//swal
import Swal from 'sweetalert2';
//services
import UserContext from '../../services/userProvider';
import productos from '../../services/products';
import api from "../../services/api"

import { useNavigate } from "react-router-dom";
import purchaserOrderService from '../../services/purchaserOrderService';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ModalEditProductInventory = (props) => {
    const { product } = props;
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState(product?.name || '')
    const [sku, setSKU] = React.useState(product?.sku || '')
    const [wareHouse, setWareHouse] = React.useState(product?.wareHouse || '')
    const [state, setState] = React.useState(product?.state || 0)
    const [observaciones, setObservaciones] = React.useState(product?.observations || 0)
    const [partNumber, setPartNumber] = React.useState(product?.partNumber || '')
    const [errorAlerta, setErrorAlerta] = React.useState("")

    const [listBodega, setListBodega] = React.useState([])

    const optionState = ["Bien", "Con defectos"];

    const fetchData = async () => {
        const body = {
            database: "warehouse",

        };

        await api.post('/global/getAll', body).then(res => {
            setListBodega(res.data.stores)
        }).catch(error => console.log(error))
    }


    React.useEffect(() => {
        fetchData();
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleSubmit = () => {
        setOpen(false);
    }

    const updateStockroom = async () => {
        setErrorAlerta("")
        if (wareHouse) {
            purchaserOrderService.updateStockroom(product._id, wareHouse, product.name, product.sku, product.stock, product.observations)
                .then(res => {
                    handleClose()
                    navigate(0);
                })
        } else {
            setErrorAlerta("ERROR: Seleccione una bodaga")
        }

    }

    return (
        <>
            <MenuItem disableRipple onClick={handleClickOpen}>
                <EditIcon />
                Editar
            </MenuItem>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" >
                            Editar - {product?.name}
                        </Typography>
                        <Button variant="outlined" color="outlined" onClick={updateStockroom}>
                            Guardar
                        </Button>
                    </Toolbar>
                </AppBar>
                <Box
                    component="form"
                    sx={{
                        alignItems: 'center',
                        '& > :not(style)': { m: 1 },
                    }}
                    autoComplete="off"
                >
                    <div>
                        <Grid container spacing={1}>
                            {/* <Grid item xs={12} sm={6}>
                                <TextField fullWidth label="Nombre del Producto" id="name" margin="normal" value={name}
                                    required onChange={(e) => setName(e.target.value)} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth label="Número de Parte" id="name" margin="normal" value={partNumber}
                                    onChange={(e) => setPartNumber(e.target.value)} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl margin="normal" fullWidth >
                                    <InputLabel >SKU</InputLabel>
                                    <OutlinedInput
                                        label="sku"
                                        value={sku}
                                        onChange={(e) => setSKU(e.target.value)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}> */}
                            <FormControl
                                margin="normal"
                                fullWidth>
                                <InputLabel id="demo-simple-select-label">Bodega</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Bodega"
                                    defaultValue={wareHouse}
                                    value={wareHouse}
                                    onChange={(e) => setWareHouse(e.target.value)}
                                >
                                    {listBodega.map(item => (
                                        <MenuItem value={item._id} key={item._id}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {/* </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    margin="normal"
                                    id="outlined-select-currency"
                                    select
                                    label="Estado"
                                    defaultValue={state}
                                    fullWidth={true}
                                    onChange={(e) => setState(e.target.value)}
                                >
                                    {optionState.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="normal"
                                    id="standard-multiline-static"
                                    value={observaciones}
                                    label="Observación"
                                    onChange={(e) => setObservaciones(e.target.value)}
                                    fullWidth
                                    multiline
                                    rows={2}
                                />
                            </Grid>*/}
                        </Grid>
                    </div>
                </Box>
                {errorAlerta && (
                    <Alert severity={errorAlerta === "Producto actualizado" ? "success" : "error"} >{errorAlerta}</Alert>
                )}
            </Dialog>
        </>
    );
}

export default ModalEditProductInventory
