// ** React Imports
import { useState, useEffect } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Alert from '@mui/material/Alert'
import Select from '@mui/material/Select'
import { styled } from '@mui/material/styles'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import InputLabel from '@mui/material/InputLabel'
import AlertTitle from '@mui/material/AlertTitle'
import IconButton from '@mui/material/IconButton'
import CardContent from '@mui/material/CardContent'
import FormControl from '@mui/material/FormControl'
import Button from '@mui/material/Button'

// ** Icons Imports
import Close from '@mui/icons-material/CloseOutlined';
import ProfileImage from "../../assets/images/Default_Profile.png"

import api from "../../services/api"
import userService from '../../services/userService'

const ImgStyled = styled('img')(({ theme }) => ({
    width: 120,
    height: 120,
    marginRight: theme.spacing(6.25),
    borderRadius: theme.shape.borderRadius
}))

const ButtonStyled = styled(Button)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        textAlign: 'center'
    }
}))

const ResetButtonStyled = styled(Button)(({ theme }) => ({
    marginLeft: theme.spacing(4.5),
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginLeft: 0,
        textAlign: 'center',
        marginTop: theme.spacing(4)
    }
}))

const TabAccount = (props) => {
    const { user } = props
    // ** State
    const [openAlert, setOpenAlert] = useState(false)
    const [imgSrc, setImgSrc] = useState(user?.image || ProfileImage)
    const [name, setName] = useState(user?.name || '')
    const [email, setemail] = useState(user?.email || '')
    const [role, setRole] = useState(user?.role || '')
    const [perfil, setPerfil] = useState('Administrador')
    const [listaPerfiles, setListaPerfiles] = useState([])

    const getPerfiles = async () => {
        await api.get('/perfil/').then(res => {
            let data = res.data
            setListaPerfiles(data)
            const result = data.filter(item => item._id === user.perfil);
            setPerfil(result[0].name)
        })

    }
    //Efffect para obtener los perfiles
    useEffect(() => {
        getPerfiles()
    }, [])

    const onChange = file => {
        const reader = new FileReader()
        const { files } = file.target
        if (files && files.length !== 0) {
            reader.onload = () => setImgSrc(reader.result)
            reader.readAsDataURL(files[0])
        }
    }
    const updateUser = () => {
        userService.updateUser(user._id, imgSrc, name, email, perfil, role).then(res => {
            console.log(res)
        })
    }
    return (
        <CardContent>
            <form>
                <Grid container spacing={7}>
                    <Grid item xs={12} sx={{ marginTop: 4.8, marginBottom: 3 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <ImgStyled src={imgSrc} alt='Profile Pic' />
                            <Box>
                                <ButtonStyled component='label' variant='contained' htmlFor='account-settings-upload-image'>
                                    Subir nueva foto
                                    <input
                                        hidden
                                        type='file'
                                        onChange={onChange}
                                        accept='image/png, image/jpeg'
                                        id='account-settings-upload-image'
                                    />
                                </ButtonStyled>
                                <ResetButtonStyled color='error' variant='outlined' onClick={() => setImgSrc(ProfileImage)}>
                                    Restablecer
                                </ResetButtonStyled>
                                <Typography variant='body2' sx={{ marginTop: 5 }}>
                                    Permitido PNG o JPEG. Tamaño máximo de 800K.
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth label='Nombre de Usuario' placeholder='Ingrese el nombre de Usuario'
                            value={name}
                            onChange={(e) => setName(e.target.value)} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            type='email'
                            label='Email'
                            value={email}
                            onChange={(e) => setemail(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel>Perfil</InputLabel>
                            <Select label='Role' defaultValue={perfil}
                                value={perfil}
                                onChange={(e) => setPerfil(e.target.value)}>
                                {listaPerfiles.map(item => (
                                    <MenuItem value={item.name} key={item._id}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            type='text'
                            label='Role'
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                        />
                    </Grid>
                    {openAlert ? (
                        <Grid item xs={12} sx={{ mb: 3 }}>
                            <Alert
                                severity='warning'
                                sx={{ '& a': { fontWeight: 400 } }}
                                action={
                                    <IconButton size='small' color='inherit' aria-label='close' onClick={() => setOpenAlert(false)}>
                                        <Close fontSize='inherit' />
                                    </IconButton>
                                }
                            >
                                <AlertTitle>Your email is not confirmed. Please check your inbox.</AlertTitle>
                                <Link href='/' onClick={e => e.preventDefault()}>
                                    Resend Confirmation
                                </Link>
                            </Alert>
                        </Grid>
                    ) : null}

                    <Grid item xs={12}>
                        <Button variant='contained' sx={{ marginRight: 3.5 }} onClick={updateUser}>
                            Guardar
                        </Button>
                        <Button type='reset' variant='outlined' color='secondary'>
                            Cancelar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </CardContent>
    )
}

export default TabAccount